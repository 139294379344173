/*
 * @description: adjustment page method
 * @Author: Zhanyu Shen
 * @Date: 2021-07-29 22:33:48
 * @LastEditors: Zhanyu Shen
 * @LastEditTime: 2022-03-08 15:50:30
 */
import { adjustmentIsOutOfStockGio } from '@/utils/index';
import {
  wallProductDrawing,
  floorProductDrawing,
  TVSize,
} from '@/utils/enum';
import { SPACE25D } from '@/utils/const';
import { cloneDeep } from 'lodash';

/**
 * @description: 遍历每个类型的门板数组
 * @param {*}
 * @return {*}
 * @author: Zhanyu Shen
 */
const iterationMccDoors = (doorData, defaultAppearanceIds = []) => {
  const resultData = [];
  const tempAppearanceIds = [];
  doorData.forEach((item) => {
    if (defaultAppearanceIds.includes(item.appearanceId)) {
      tempAppearanceIds.push(item.appearanceId);
    }
    resultData.push({
      ...item,
      id: item.appearanceId,
      img: item.imageUrl,
    });
  });
  return { data: resultData, defaultValue: tempAppearanceIds };
};

/**
 * @description: Group_type: 1=高光门板，2=哑光门板，3=功能门板，4=木色门板，5=艺术门板
 * 处理从后端获取的门板数据
 * @param {Object} doorList 后端获取的门板数据
 * @param {Array} defaultDoor mcc中含有的门板
 * @param {Function} t i18n function
 * @return {*}
 * @author: Zhanyu Shen
 */
const getDoorData = (doorList, defaultDoor = [], t) => {
  const mccDoors = [];
  const defaultDoorIds = [];
  if (doorList && Object.keys(doorList).length > 0) {
    if (doorList.glossy && doorList.glossy.length > 0) {
      const tempGlossys = iterationMccDoors(doorList.glossy, defaultDoor);
      mccDoors.push({ name: t('adjustment.highlight'), data: tempGlossys.data });
      defaultDoorIds.push(...tempGlossys.defaultValue);
    }
    if (doorList.matte && doorList.matte.length > 0) {
      const tempMattes = iterationMccDoors(doorList.matte, defaultDoor);
      mccDoors.push({ name: t('adjustment.matte'), data: tempMattes.data });
      defaultDoorIds.push(...tempMattes.defaultValue);
    }
    if (doorList.function && doorList.function.length > 0) {
      const tempFunctions = iterationMccDoors(doorList.function, defaultDoor);
      mccDoors.push({ name: t('adjustment.feature'), data: tempFunctions.data });
      defaultDoorIds.push(...tempFunctions.defaultValue);
    }
    if (doorList.wood && doorList.wood.length > 0) {
      const tempWoods = iterationMccDoors(doorList.wood, defaultDoor);
      mccDoors.push({ name: t('adjustment.wood'), data: tempWoods.data });
      defaultDoorIds.push(...tempWoods.defaultValue);
    }
    if (doorList.art && doorList.art.length > 0) {
      const tempArts = iterationMccDoors(doorList.art, defaultDoor);
      mccDoors.push({ name: t('adjustment.art'), data: tempArts.data });
      defaultDoorIds.push(...tempArts.defaultValue);
    }
    if (doorList.glass && doorList.glass.length > 0) {
      const tempArts = iterationMccDoors(doorList.glass, defaultDoor);
      mccDoors.push({ name: t('adjustment.glass'), data: tempArts.data });
      defaultDoorIds.push(...tempArts.defaultValue);
    }
  }
  return { data: mccDoors, defaultValue: Array.from(new Set(defaultDoorIds)) };
};

/**
 * @description: 获取所有墙面数据，并根据templateJson里的墙面标识设置默认墙面
 * @param {String} defaultWallId
 * @return {Array}
 * @author: Zhanyu Shen
 */
const getAccessoriesWall = (defaultWallId) => {
  const wall = cloneDeep(wallProductDrawing);
  if (defaultWallId) {
    const defaultWallIndex = wall.findIndex((item) => item.id === defaultWallId);
    if (defaultWallIndex > 0) {
      wall[0].default = false;
      wall[defaultWallIndex].default = true;
    }
  }
  return wall;
};

/**
 * @description: 获取所有墙面数据，并根据templateJson里的墙面标识设置默认墙面
 * @param {String} defaultWallId
 * @return {Array}
 * @author: Zhanyu Shen
 */
const getAccessoriesFloor = (defaultFloorId) => {
  const floor = cloneDeep(floorProductDrawing);
  if (defaultFloorId) {
    const defaultFloorIndex = floor.findIndex((item) => item.id === defaultFloorId);
    if (defaultFloorIndex > 0) {
      floor[0].default = false;
      floor[defaultFloorIndex].default = true;
    }
  }
  return floor;
};

/**
 * @description: 获取所有电视机数据，设置MCC最大电视机和用户选择的电视机
 * @param {String} mccMaxTV MCC可放置的最大电视机ID
 * @param {String}  mccUserTV 用户选择的电视机ID
 * @return {*}
 * @author: Zhanyu Shen
 */
const getAccessoriesTV = (mccMaxTV, mccUserTV) => {
  const tv = cloneDeep(TVSize);
  if (mccMaxTV) {
    const maxTvIndex = tv.findIndex((item) => item.id === mccMaxTV);
    const defaultTvIndex = tv.findIndex((item) => item.id === mccUserTV);
    if (defaultTvIndex > 0) {
      tv[0].default = false;
      tv[defaultTvIndex].default = true;
    } else if (maxTvIndex > 0 && defaultTvIndex === -1) {
      tv[0].default = false;
      tv[maxTvIndex].default = true;
    }
    if (maxTvIndex > 0) {
      tv[maxTvIndex].max = true;
    }
  }
  return tv;
};

/**
 * @description: 根据框体深度获取框体侧板宽
 * @param {*} frame
 * @return {*}
 * @author: Zhanyu Shen
 */
const getFrameSideSpaceByDeep = (deep) => {
  let sideSpace = 0;
  if (deep) {
    switch (Number.parseInt(deep, 10)) {
      case 20:
        sideSpace = SPACE25D.DEEP20SIDESPACE;
        break;
      case 40:
        sideSpace = SPACE25D.DEEP40SIDESPACE;
        break;
      default:
        break;
    }
  }
  return sideSpace;
};

/**
 * @description: 根据框体深度获取框体顶部留白高
 * @param {*} frame
 * @return {*}
 * @author: Zhanyu Shen
 */
const getFrameTopSpaceByDeep = (deep) => {
  let topSpace = 0;
  if (deep) {
    switch (Number.parseInt(deep, 10)) {
      case 20:
        topSpace = SPACE25D.DEEP20TOPSPACE;
        break;
      case 40:
        topSpace = SPACE25D.DEEP40TOPSPACE;
        break;
      default:
        break;
    }
  }
  return topSpace;
};

const doorIsOutOfStockGio = (door) => {
  const tabName = 'door';
  const tabChildName = door.name;
  let isOutOfStock = 'N';
  door.data.forEach((d) => {
    if (d.stockOutStatus) {
      isOutOfStock = 'Y';
    }
  });
  adjustmentIsOutOfStockGio(isOutOfStock, tabName, tabChildName);
};

const adjustMentMenusIsOutOfStock = (tabContent, index) => {
  const content = tabContent[index];
  const { groups } = content;
  if (groups && groups.length > 0) {
    let isOutOfStock = 'N';
    const tabName = index;
    let tabChildName = '-';
    if (index === 'door') {
      tabChildName = groups[0].name;
    }
    groups[0].data.forEach((item) => {
      if (item.stockOutStatus) {
        isOutOfStock = 'Y';
      }
    });
    adjustmentIsOutOfStockGio(isOutOfStock, tabName, tabChildName);
  }
};

export {
  getDoorData,
  iterationMccDoors,
  getAccessoriesWall,
  getAccessoriesFloor,
  getAccessoriesTV,
  getFrameSideSpaceByDeep,
  getFrameTopSpaceByDeep,
  doorIsOutOfStockGio,
  adjustMentMenusIsOutOfStock,
};
