/* eslint-disable no-case-declarations */
/*
 * @FilePath: \besta-online-web\src\adaptPlatform\index.js
 * @Author: yehao Gao
 * @Date: 2021-08-03 17:30:46
 * @LastEditors: Yehao Gao
 * @LastEditTime: 2022-05-06 17:02:33
 */
import ikea from 'ikea-jssdk';
// import DefaultModule from '@/ikea-routers/defaultModule';
import jsonwebtoken from 'jsonwebtoken';
import { getCookie } from '@/utils/index';
// eslint-disable-next-line
import DefaultModule from '@/ikea-routers/defaultModule';
import Account from '@/ikea-routers/account.ts';
import { appSaveImage } from './app';

const navigateToHome = () => {
  ikea.navigateTo(DefaultModule.Home());
};

// 是否登录
const isLogin = async () => {
  // const { value } = await ikea.isLogin();
  const res = await ikea.isLogin();
  return res;
};

// 获取userId
const UID_KEY = 'partyUId';

const getUserId = () => {
  const type = sessionStorage.getItem('currentPlatform');
  let userId = '';
  switch (type) {
    case 'smp':
      break;
    case 'app':
      try {
        const obj = jsonwebtoken.decode(getCookie('token') || '') || {};
        console.log('app token:', obj);
        console.log('ud:', obj[UID_KEY]);
        userId = obj[UID_KEY] || '';
        // alert(userId);
      } catch (e) {
        console.log('get token error!');
      }
      break;
    case 'ikeacn':
      break;
    default:
      break;
  }
  return userId;
};

const TOKEN = getCookie('token');

// 获取token
const getToken = async () => {
  const res = await ikea.getToken();
  return res;
};

// 判断app版本
const compareVersion = (version1, version2) => {
  const arr1 = version1.split('.');
  const arr2 = version2.split('.');

  const len = Math.max(arr1.length, arr2.length);
  for (let i = 0; i < len; i += 1) {
    let data1 = 0;
    let data2 = 0;
    if (i < arr1.length) {
      data1 = parseInt(arr1[i], 10);
    }
    if (i < arr2.length) {
      data2 = parseInt(arr2[i], 10);
    }

    if (data1 < data2) {
      return -1;
    }
    if (data1 > data2) {
      return 1;
    }
  }
  return 0;
};

export const SUPPORT_JS_BRIGE_IMAGE = compareVersion(getCookie('app_version') || '0', '2.4.0') >= 0;

// 设备类型
const currentPlatform = async () => {
  const res = await ikea.currentPlatform();
  const {
    value: {
      platform,
    } = {},
  } = res;


  const typeObj = {
    WechatMiniprogram: 'smp',
    ANDROID: 'app',
    iOS: 'app',
    PcWeb: 'ikeacn',
    MobileWeb: 'ikeacn',
    unknown: 'unknown',
  };

  return typeObj[platform];
};

const getGioNeedPlatform = async () => {
  const res = await ikea.currentPlatform();
  let gioPlatform = '';
  switch (res?.value?.platform) {
    case 'PcWeb':
      gioPlatform = 'pc';
      break;
    case 'MobileWeb':
      gioPlatform = 'h5';
      break;
    case 'unknown':
      gioPlatform = 'pc';
      break;
    default:
      gioPlatform = res?.value?.platform;
      break;
  }
  return gioPlatform;
};
// 跳转CSC客服
// eslint-disable-next-line
const toCSC = ({ autoText = '', channel = 'besta' }) => {
  const current = sessionStorage.getItem('currentPlatform');
  if (current === 'smp' || current === 'app') {
    ikea.navigateTo(DefaultModule.CustomerService({ channel, autoText }));
  } else {
    window.location.href = 'https://kefu.csc.ikea.cn/webim/im.html'
    + '?configId=4ddd4cac-0db9-42c0-907b-2ef3c9b6dd9a';
  }
};

export async function getCscChannel() {
  let channel = 'H5';
  try {
    const res = await ikea.currentPlatform();
    const {
      value: {
        platform,
      } = {},
    } = res;
    switch (platform) {
      case 'iOS':
        channel = 'IOS';
        break;
      case 'ANDROID':
        channel = 'ANDROID';
        break;
      case 'WechatMiniprogram':
        channel = 'SMP';
        break;
      case 'PcWeb':
        channel = 'WEB';
        break;
      case 'MobileWeb':
        channel = 'Mobile_Web';
        break;
      case 'unknown':
      default:
        channel = 'H5';
        break;
    }
  } catch (err) {
    console.log(err);
  }
  return channel;
}

// function isProd() {
//   return window.location.host === 'besta-online-web.aidesign.ingka-internal.cn';
// }

const toNewCSC = async ({ autoText = '' }) => {
  ikea.navigateTo(DefaultModule.CustomerService({
    page: 'Besta',
    intent: 'Besta',
    autoText,
  }))
    .then((data) => {
      if (data.type === 'success') {
        // 调用成功
        console.log('ikea sdk: 联系客服调用成功');
      }
      if (data.type === 'fail') {
        console.log('ikea sdk: 联系客服调用失败');
      }
    })
    .catch((err) => {
      console.log(err);
    });
  // const channel = await getCscChannel();
  // const cscUrl = isProd()
  //   ? 'https://kefu.csc.ikea.cn/webim/im.html' : 'https://cnos-ppe-im.ingka-dt.cn/webim/im.html';
  // const url = `${cscUrl}?channel=${channel}&page=Besta_Design&autoText=${autoText}`;
  // return ikea.openH5(url)
  //   .then((data) => {
  //     if (data.type === 'success') {
  //       // 调用成功
  //       console.log('ikea sdk: 联系客服调用成功');
  //     }
  //     if (data.type === 'fail') {
  //       console.log('ikea sdk: 联系客服调用失败');
  //     }
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });
};

// 保存图片
const saveImg = ({ link, poster, title }) => {
  const type = sessionStorage.getItem('currentPlatform');
  switch (type) {
    case 'smp':
      ikea.navigateTo({
        smp: {
          path: '@command_shareLink({"link":"{{link}}","path":"{{path}}","poster":"{{poster}}","title":"{{title}}"})',
        },
        app: {
          path: `@command_shareLink({"link":"{{link}}","path":"{{path}}","poster":"{{poster}}",
          "title":"{{title}}","trackSource":"{{trackSource}}"})`,
          version: '>=2.0.0',
        },
        web: {
          visitPath: `
          /sdk-command/share-link?link=@command_encode({{link}})&path=@command_encode({{path}})&title={{title}}`,
        },
        params: {
          link: {
            type: 'optional',
            desc: 'the full h5 link',
          },
          path: {
            type: 'optional',
            desc: 'the native path for smp、web、app',
          },
          poster: {
            type: 'required',
            desc: 'the poster that can be saved',
          },
          title: {
            type: 'required',
            desc: 'the title in wechat sharing',
          },
          trackSource: {
            type: 'optional',
            desc: 'the share source，only used in app',
          },
        },
        receivedParams: {
          link,
          poster,
          title,
        },
      });
      break;
    case 'app':
      appSaveImage(poster);
      break;
    case 'ikeacn':
      (() => {
        console.log('111');
        const a = document.createElement('a');
        a.download = `${new Date().getTime()}.png`;
        a.href = poster;
        a.click();
      })();
      break;
    default:
      (() => {
        console.log('111');
        const a = document.createElement('a');
        a.download = `${new Date().getTime()}.png`;
        a.href = poster;
        a.click();
      })();
      break;
  }
};

// 只有app能够获取当前的zipCode
const getCity = async () => {
  console.log('window.flutter_inappwebview', window.flutter_inappwebview);
  if (!window.flutter_inappwebview) return null;
  try {
    if (typeof window.flutter_inappwebview?.callHandler === 'function') {
      console.log('获取native city成功');
      return await window.flutter_inappwebview.callHandler('getCity');
    }
    console.log('获取native city失败');
    return new Promise((resolve) => {
      window.addEventListener('flutterInAppWebViewPlatformReady', async () => {
        console.log('等待初始化获取城市');
        const cityInfo = await window.flutter_inappwebview.callHandler('getCity');
        console.log('获取城市成功', JSON.stringify(cityInfo));
        resolve(cityInfo);
      });
    });
  } catch (e) {
    console.log('getCity:', e);
    return null;
  }
};

// 获取地理位置信息
const getAddressInfo = () => ikea.getAddressInfo();

// 加入购物车
const addToCart = async (params, isclose = false, isJump = false) => {
  const type = sessionStorage.getItem('currentPlatform');
  const smpparams = params.map(({ productFullId, quantity }) => { // smp
    const isVTP = /^v/i.test(productFullId);
    return {
      id: isVTP ? productFullId.slice(1) : productFullId,
      itemType: isVTP ? 'VTP' : 'ART',
      quantity,
    };
  });
  const appparams = { products: params }; // app或ikeacn
  switch (type) {
    case 'smp':
      if (isJump) {
        await ikea.navigateTo(DefaultModule.AddProductsToShoppingCart({
          products: JSON.stringify(smpparams),
        }));
      } else {
        await ikea.request({
          path: '/shopping/cart/add-products',
          method: 'POST',
          withToken: true,
          headers: {
            'X-Client-Platform': 'WechatMiniprogram',
          },
          data: {
            products: params, // param: {"products": [{"productFullId": XXXX, "quantity": 1}]}
          },
        });
      }
      break;
    case 'app':
      const res = await window.flutter_inappwebview.callHandler('addToCart', appparams);
      if (isJump && res.result === 'true') { // 是否跳转页面
        await window.flutter_inappwebview.callHandler('push', {
          path: 'https://ikeaapp/shopping_cart',
          popCurrent: isclose, // 是否关闭当前页
        });
      }
      break;
    case 'ikeacn':
      await ikea.request({
        path: '/shopping/cart/add-products',
        method: 'POST',
        withToken: true,
        headers: {
          'X-Client-Platform': getCookie('platform') || 'MobileWeb',
        },
        data: {
          products: params, // param: {"products": [{"productFullId": XXXX, "quantity": 1}]}
        },
      });
      if (isJump) {
        await ikea.navigateTo(DefaultModule.ShoppingCart());
      }
      break;
    default:
      await ikea.request({
        path: '/shopping/cart/add-products',
        method: 'POST',
        withToken: true,
        headers: {
          'X-Client-Platform': getCookie('platform') || 'MobileWeb',
        },
        data: {
          products: params, // param: {"products": [{"productFullId": XXXX, "quantity": 1}]}
        },
      });
      if (isJump) {
        await ikea.navigateTo(DefaultModule.ShoppingCart());
      }
      break;
  }
};

// const goLogin = (templateId = undefined, templateType = undefined, fn = () => {}) => {
const goLogin = ({
  templateId, templateType, mccComboId, fn,
}) => {
  if (sessionStorage.getItem('currentPlatform') === 'ikeacn') {
    isLogin().then(({ value }) => {
      if (!value) {
        if (templateId && templateType) {
          // eslint-disable-next-line
          ikea.navigateTo(Account.Login({ redirectUri: `${window.location.origin}/besta-online/pip?templateId=${templateId}&templateType=${templateType}&mccComboId=${mccComboId}` }));
        } else {
          ikea.navigateTo(Account.Login(
            {
              redirectUri:
                `${window.location.origin}/besta-online/`,
            },
          ));
        }
      } else if (fn) {
        fn();
      }
    });
  } else {
    isLogin().then(({ value }) => {
      if (!value) {
        ikea.navigateTo(Account.Login());
      } else if (fn) {
        fn();
      }
    });
  }
};

// 获取用户手机号和昵称
const getUserInfo = (fn) => {
  ikea.getUserInfo().then((result) => {
    fn(result);
  });
};

/**
 * 分享（其中就有保存）
 * @param {*} ikea sdk 需要的参数: link, poster, title
 */
const share = (param) => ikea.navigateTo(DefaultModule.Share(param));
export {
  navigateToHome,
  currentPlatform,
  getCity,
  saveImg,
  isLogin,
  toCSC,
  getUserId,
  addToCart,
  TOKEN,
  getToken,
  goLogin,
  getUserInfo,
  getAddressInfo,
  share,
  getGioNeedPlatform,
  toNewCSC,
};
