/*
 * @FilePath: \besta-online-web\src\ErrorBoundary.js
 * @Author: Yongchao Wang
 * @Date: 2022-03-30 15:08:19
 * @LastEditors: Zhanyu Shen
 * @LastEditTime: 2022-07-19 12:14:38
 */
import React from 'react';
import { sendLog } from './error';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    console.log(error, 'getDerivedStateFromError');
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // 你同样可以将错误日志上报给服务器
    sendLog(error.toString() + JSON.stringify(errorInfo), 'componentDidCatch');
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      window.location.reload();
      // 你可以自定义降级后的 UI 并渲染
      return <h1>Something went wrong.</h1>;
    }

    // eslint-disable react/destructuring-assignment && react/prop-types
    // eslint-disable-next-line react/prop-types
    const { children } = this.props;
    return children;
  }
}
export default ErrorBoundary;
