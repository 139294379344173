/*
 * @Description: Description
 * @Author: Xiaohua Zhu
 * @Date: 2021-12-31 10:20:14
 * @LastEditors: Dai Guo
 * @LastEditTime: 2022-05-05 16:45:18
 */
const adjustmentAction = {
  setRender: (state, payload) => ({
    ...state,
    renderData: payload,
  }),
  setTabIndex: (state, payload) => ({
    ...state,
    tabIndex: payload,
  }),
  setIsShowPopover: (state, payload) => ({
    ...state,
    isShowPopover: payload,
  }),
  setIsShowDetailPopover: (state, payload) => ({
    ...state,
    isShowDetailPopover: payload,
  }),
  setMccComboId: (state, payload) => ({
    ...state,
    mccComboId: payload,
  }),
  setPreviousAccessories: (state, payload) => ({
    ...state,
    previousAccessories: payload,
  }),
  setIsGoReplace: (state, payload) => ({
    ...state,
    isGoReplace: payload,
  }),
};

export default adjustmentAction;
