/*
 * @Description: Description
 * @Author: Xiaohua Zhu
 * @Date: 2021-12-31 10:20:14
 * @LastEditors: Xiaohua Zhu
 * @LastEditTime: 2021-12-31 10:25:44
 */
const sideBarAction = {
  setWallVisible: (state, payload) => ({
    ...state,
    wallVisible: payload,
  }),
  setGroundVisible: (state, payload) => ({
    ...state,
    groundVisible: payload,
  }),
  setTvVisible: (state, payload) => ({
    ...state,
    tvVisible: payload,
  }),

};

export default sideBarAction;
