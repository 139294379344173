/*
 * @Description: Description
 * @Author: Zhanyu Shen
 * @Date: 2021-07-03 16:48:39
 * @LastEditors: zhen Guo
 * @LastEditTime: 2021-07-30 15:11:41
 */
const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({
      getCLS, getFID, getFCP, getLCP, getTTFB,
    }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
