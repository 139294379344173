/*
 * @description: 根据version初始化对应版本的render class
 * @Author: Zhanyu Shen
 * @Date: 2022-02-16 15:27:20
 * @LastEditors: Zhanyu Shen
 * @LastEditTime: 2022-03-08 11:24:17
 */
import BestaMcc2DActionEvent from './2DRender/BestaMcc2DActionEvent';
import BestaMcc25DActionEvent from './25DRender/BestaMcc25DActionEvent';
class CreateRender {
    /**
     * @description 根据不同json版本进行对应的MCC渲染
     * @param version template json version
     */
    static Initialization(version) {
        let bestaMcc = null;
        switch (version) {
            case '1.0':
                bestaMcc = new BestaMcc2DActionEvent(); // 伪2.5D效果，柜体只有顶部视角
                break;
            case '2.0':
                bestaMcc = new BestaMcc25DActionEvent(); // 2.5D效果，柜体含有顶部和侧板视角
                break;
            default:
                bestaMcc = new BestaMcc2DActionEvent();
                break;
        }
        return bestaMcc;
    }
}
export default CreateRender;
