/*
 * @description: React main file
 * @Author: Zhanyu Shen
 * @Date: 2021-07-15 20:54:05
 * @LastEditors: Yongchao Wang
 * @LastEditTime: 2022-05-25 10:09:50
 */
import './error'; // 错误日志上报
import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line
import ikea from 'ikea-jssdk';
import './index.css';
import '@ingka/loading/dist/style.css';
import { BrowserRouter } from 'react-router-dom';
import './intl18/index';
import {
  currentPlatform,
  isLogin,
  getToken,
  getUserInfo,
  getAddressInfo,
  getGioNeedPlatform,
} from '@/adaptPlatform/index';
import { abtestGio, smoothLandingAbTestGio } from '@/utils/index';
import { getUserId } from '@/apis/mcc';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import reportWebVitals from './reportWebVitals';
import App from './App';
import ErrorBoundary from './ErrorBoundary';

if (process.env.REACT_APP_ENV !== 'production' && process.env.REACT_APP_ENV !== 'development') {
// eslint-disable-next-line global-require
  const VConsole = require('vconsole');
  // eslint-disable-next-line
  var vConsole = new VConsole();
}
if (process.env.REACT_APP_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://f1584689b80a4997b38794bf2834dea2@sentry.ingka-system.cn/57',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENV,
    release: process.env.REACT_APP_VERSION,
  });
}

ikea.init({
  refer: {
    referId: 'beata-online',
  },
  env: process.env.REACT_APP_ENV === 'test' ? 'qa' : 'prod', // 此处设置qa，则调用jssdk测试的接口，上线前需要删除或改为prod
});

getGioNeedPlatform().then((giores) => {
  sessionStorage.setItem('gioPlatform', giores);
});
currentPlatform().then((res) => {
  sessionStorage.setItem('currentPlatform', res);
  if (res === 'smp') {
    document.title = '贝达电视柜方案';
  }

  if (localStorage.getItem('newUser') && localStorage.getItem('newUser').indexOf('[') === -1) {
    localStorage.setItem('newUser', '[]');
  }


  getToken().then(({ value }) => {
    // eslint-disable-next-line
    sessionStorage.setItem('token', value);

    // 当宜家首页注销后，这边拿不到token得把userId清除
    if (!value) {
      sessionStorage.removeItem('userId');
    }

    // if (res === 'smp') {
    //   document.addEventListener('visibilitychange', () => {
    //     setTimeout(() => {
    //       isLogin().then((result) => {
    //         console.log('result', result.value);
    //         if (!result.value && !document.hidden) {
    //           // setTimeout(() => {
    //           ikea.navigateBack();
    //           // }, 500);
    //         }
    //       });
    //     }, 1500);
    //   });
    // }

    sessionStorage.removeItem('name');
    sessionStorage.removeItem('mobile');
    getUserInfo((result) => {
      console.log('getUserInfo', result);
      if (result.type === 'success' && result.value.mobile && result.value.mobile !== '') {
        sessionStorage.setItem('name', result.value.firstName);
        sessionStorage.setItem('mobile', result.value.mobile);
        sessionStorage.setItem('hashPartyUID', result.value.hashedPartyUid);
        window.gio('setUserId', result.value.hashedPartyUid);
      } else {
        window.gio('clearUserId');
      }
    });


    const r1 = new Promise((resolve, reject) => {
      getUserId({ token: value }).then((result) => {
        try {
          sessionStorage.setItem('userId', result.data.data.userId || '');
          resolve();
        } catch (e) { reject(); }
      }).catch(() => {
        reject();
      });
    });


    const r2 = new Promise((resolve, reject) => {
      getAddressInfo().then(({ value: { zipcode, province, city } }) => {
        if (zipcode) {
          sessionStorage.setItem('zipcode', zipcode);
          sessionStorage.setItem('province', province);
          sessionStorage.setItem('city', city);
        }
        resolve();
      }).catch((e) => {
        console.log(e);
        reject();
      });
    });

    const r3 = new Promise((resolve) => { // ABtest
      ikea.abtest.getVariation({
        // 实验id
        experimentId: 'besta_design_layout',
        // 如果数据获取失败的默认数据
        defaultVariation: 'grid',
      }).then((layout) => {
        setTimeout(() => {
          abtestGio(layout.value);
        }, 300);
        sessionStorage.setItem('layout', layout.value);
        resolve();
      });
    });

    // bes-1324abtest 组装服务
    // const r4 = new Promise((resolve) => {
    //   ikea.abtest
    //     .getVariation({
    //       // 实验id
    //       experimentId: 'BESTA_assembly_AB',
    //       // 如果数据获取失败的默认数据
    //       defaultVariation: 'BUser',
    //     })
    //     .then((service) => {
    //       setTimeout(() => {
    //         window.gio('visitor.set', 'abtest_besta_assembly_AB', service.value);
    //       }, 300);
    //       sessionStorage.setItem('serviceUser', service.value);
    //       resolve();
    //     });
    // });

    const renderApp = () => { // ABtest 欢迎页
      ikea.abtest.getVariation({
        // 实验id
        experimentId: 'besta_smooth_landing',
        // 如果数据获取失败的默认数据
        defaultVariation: 'AUser',
      }).then((user) => {
        setTimeout(() => {
          smoothLandingAbTestGio(user.value);
        }, 300);
        ReactDOM.render(
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <ErrorBoundary>
              <App abtest={user.value} />
            </ErrorBoundary>
          </BrowserRouter>,
          document.getElementById('root'),
        );
      });
    };

    if (res !== 'ikeacn') {
      isLogin().then(({ val }) => {
        if (!val) {
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userId');
        }
      });
    }

    Promise.all([r1, r2, r3])
      .finally(() => {
        renderApp();
        if (sessionStorage.getItem('zipcode') === 'null') {
          sessionStorage.setItem('zipcode', '');
        }
      });
  });


  // if (res !== 'ikeacn') {
  //   isLogin().then(({ value }) => {
  //     if (!value) {
  //       if (res !== 'app') {
  //         ikea.navigateTo(Account.Login(
  //           //   {
  //           //   redirectUri:
  //           //     'https%3A%2F%2Fbesta-online-web-dev.aidesign.ingka-dt.cn%2Fbesta-online%2F',
  //           // }
  //         ), {
  //           escapeParams:
  //                   { redirect_uri: 'https%3A%2F%2Fbesta-online-web-dev.aidesign.ingka-dt.cn%2Fbesta-online%2F' },
  //         });
  //       } else {
  //         ikea.navigateTo(Account.Login()).then((data) => {
  //           console.log('data', data);
  //           setTimeout(() => {
  //             isLogin().then((result) => {
  //               console.log(result.value);
  //               if (!result.value) {
  //                 if (res === 'smp') {
  //                   setTimeout(() => {
  //                     ikea.navigateBack();
  //                   }, 500);
  //                 } else {
  //                   ikea.navigateBack();
  //                 }
  //               }
  //             });
  //           }, 500);
  //         });
  //       }
  //     } else {
  //       ReactDOM.render(
  //         <BrowserRouter basename={process.env.PUBLIC_URL}>
  //           <App />
  //         </BrowserRouter>,
  //         document.getElementById('root'),
  //       );
  //     }
  //   });
  // } else {

  // Promise.all([r1, r2]).then(() => {
  //   console.log('userId', sessionStorage.getItem('userId'));
  //   console.log('zipcode', sessionStorage.getItem('zipcode'));
  //   ReactDOM.render(
  //     <BrowserRouter basename={process.env.PUBLIC_URL}>
  //       <App />
  //     </BrowserRouter>,
  //     document.getElementById('root'),
  //   );
  // }).catch(() => {
  //   ReactDOM.render(
  //     <BrowserRouter basename={process.env.PUBLIC_URL}>
  //       <App />
  //     </BrowserRouter>,
  //     document.getElementById('root'),
  //   );
  // });

  // }
});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
