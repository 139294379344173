/*
 * @description: Do not edit
 * @Author: Zhanyu Shen
 * @Date: 2022-01-26 10:12:53
 * @LastEditors: Zhanyu Shen
 * @LastEditTime: 2022-03-16 14:37:04
 */
import React, {
  useState,
} from 'react';
import propTypes from 'prop-types';
import style from './sideBar.module.scss';

const ColorSelect = (props) => {
  const {
    options, type, handleChange, defaultColor,
  } = props;
  const [colorList] = useState(options);
  const [selected, setSelected] = useState(defaultColor);

  const changeColor = (item) => {
    setSelected(item.id);
    handleChange(item, type);
  };
  return (
    <div
      className={`${style[`color-group-${type}`]} ${style['color-container']} `}
      onTouchStart={(e) => e.stopPropagation()}
    >
      <div className={style['color-group']}>
        {colorList
          && colorList.map((item) => (
            <div
              className={`${style['color-item']}  ${
                selected === item.id ? style['color-active'] : ''
              }`}
              style={item.iconStyle}
              key={item.id}
              onTouchStart={() => changeColor(item)}
            />
          ))}
      </div>
      <div className={style['group-arrow']} />
    </div>
  );
};

ColorSelect.propTypes = {
  options: propTypes.oneOfType([propTypes.array]),
  type: propTypes.string,
  handleChange: propTypes.func,
  defaultColor: propTypes.string,
};

ColorSelect.defaultProps = {
  options: [],
  type: '',
  handleChange() {},
  defaultColor: '',
};
export default ColorSelect;
