/*
 * @FilePath: \besta-online-web\src\intl18\index.js
 * @Author: Zhanyu Shen
 * @Date: 2021-07-06 11:06:25
 * @LastEditors: zhen Guo
 * @LastEditTime: 2021-07-30 15:09:05
 */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import zh from './locales/zh.json';
import en from './locales/en.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI:
// https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
const resources = {
  en: {
    translation: en,
  },
  zh: {
    translation: zh,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'zh',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
