import React, {
  useState, useRef, useEffect, useContext,
} from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import downArrow from '@/static/sidebar/down-arrow.svg';
import rightArrow from '@/static/sidebar/right-arrow.svg';
import { ReactComponent as FunctionalIcon } from '@/static/sidebar/functional.svg';
import { ReactComponent as WallIcon } from '@/static/sidebar/wall.svg';
import { ReactComponent as FloorIcon } from '@/static/sidebar/floor.svg';
import { ReactComponent as TvIcon } from '@/static/sidebar/tv.svg';
import SettingsIcon from '@/static/sidebar/settings.svg';
import { AppContext } from '@/redux/store';
import style from './sideBar.module.scss';

import ColorSelect from './colorSelect';
// import TvSelect from './tvSelect';
// 颜色选择组件复用


function SideBar(props) {
  const { t } = useTranslation();
  const { data, handleClick, showFunction } = props;
  // 隐藏 -1 主页面 0,功能分区 1,墙面颜色 2,地面颜色 3,电视机 4
  const iconList = [
    {
      src: <FunctionalIcon />,
      text: t('sideBar_component.functionalPartition'),
    },
    {
      src: <WallIcon />,
      text: t('sideBar_component.wallColor'),
      clickHandler: 'setWallVisible',
    },
    {
      src: <FloorIcon />,
      text: t('sideBar_component.floorColor'),
      clickHandler: 'setGroundVisible',
    },
    {
      src: <TvIcon />,
      text: t('sideBar_component.tv'),
      clickHandler: 'setTvVisible',
    },
  ];
  // const [view, setView] = useState(-1);
  // const [allData] = useState(data);
  const [wall, setWall] = useState(null);
  const [floor, setFloor] = useState(null);
  const [tv, setTV] = useState(null);
  const [selectWallIndex, setSelectWallIndex] = useState(null);
  const [selectFloorIndex, setSelectFloorIndex] = useState(null);
  const [selectTVIndex, setSelectTVIndex] = useState(0);
  const [maxTV, setMaxTV] = useState(null);
  // 向下滚动
  const [isMoving, setIsMoving] = useState(false);
  const scrollRef = useRef();
  const [settingsVisible, setSettingsVisible] = useState(false);

  const { storeState, dispatch } = useContext(AppContext);

  const { wallVisible, groundVisible, tvVisible } = storeState;
  // const changeView = (param) => {
  //   if (param !== 1) {
  //     setView(param);
  //   } else {
  //     handleClick(true, 'functional');
  //   }
  // };

  const handleTouchEnd = (param) => {
    if (param === 1) {
      // handleClick(false, 'functional');
    }
  };

  const changeSize = (param) => {
    if (isMoving) {
      return;
    }
    setSelectTVIndex(param.id);
    handleClick(
      { ...param, contrast: param.scale === 0 ? 0 : maxTV.scale / param.scale },
      'tv',
    );
  };

  const handleChangeColor = (item, type) => {
    if (type === 'wall') {
      setSelectWallIndex(item.id);
    } else {
      setSelectFloorIndex(item.id);
    }
    handleClick(item, type);
  };

  const handleScroll = () => {
    setIsMoving(true);
  };

  const initData = () => {
    if (data.wall && data.wall.length > 0) {
      const defaultWallCol = data.wall.find((item) => !!item.default);
      setSelectWallIndex(defaultWallCol.id || null);
      setWall(data.wall);
    }
    if (data.floor && data.floor.length > 0) {
      const defaultFloorCol = data.floor.find((item) => !!item.default);
      setSelectFloorIndex(defaultFloorCol.id || null);
      setFloor(data.floor);
    }
    if (data.tv && data.tv.length > 0) {
      const defaultTVCol = data.tv.find((item) => !!item.default);
      const maxTVCol = data.tv.find((item) => !!item.max) || defaultTVCol;
      const maxSize = maxTVCol.size || 0;
      const tempTV = data.tv.filter((item) => item.size <= maxSize);
      setSelectTVIndex(defaultTVCol.id || 0);
      setMaxTV(maxTVCol);
      setTV(tempTV);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (data) {
      initData();
    }
  }, [data]);

  return (
    <div>
      {
        <div
          className={`${style.container} ${
            !settingsVisible ? style['hidden-container'] : ''
          }`}
        >
          {iconList
            && iconList.map((item, index) => (
              <div
                className={`${style['icon-item']} ${index === 0 && style.iconMargin}`}
                onTouchStart={() => {
                  dispatch({ type: 'setWallVisible', payload: false });
                  dispatch({ type: 'setGroundVisible', payload: false });
                  dispatch({ type: 'setTvVisible', payload: false });
                  if (item.clickHandler) {
                    if (item.clickHandler === 'setWallVisible') {
                      dispatch({ type: item.clickHandler, payload: !wallVisible });
                    } else if (item.clickHandler === 'setGroundVisible') {
                      dispatch({ type: item.clickHandler, payload: !groundVisible });
                    } else if (item.clickHandler === 'setTvVisible') {
                      dispatch({ type: item.clickHandler, payload: !tvVisible });
                    }
                  } else {
                    handleClick(!showFunction, 'functional');
                  }
                }}
                onTouchEnd={() => {
                  handleTouchEnd(1);
                }}
                // onTouchCancel={() => handleTouchEnd(index + 1)}
                key={item.text}
                style={{
                  display: !settingsVisible ? 'none' : 'flex',
                }}
              >
                {/* <img src={item.src} alt="" className={style['icon-img']} /> */}
                {item.src}
                <div className={style['icon-text']}>{item.text}</div>
                {wallVisible && index === 1 && (
                  <ColorSelect
                    // changeView={(idx) => changeView(idx)}
                    type="wall"
                    options={wall}
                    handleChange={handleChangeColor}
                    defaultColor={selectWallIndex}
                  />
                )}
                {groundVisible && index === 2 && (
                  <ColorSelect
                    type="floor"
                    options={floor}
                    handleChange={handleChangeColor}
                    defaultColor={selectFloorIndex}
                  />
                )}
                {/* 电视机弹窗 */}
                {tvVisible && index === 3 && (
                <div className={style['display-container']} onTouchStart={(e) => e.stopPropagation()}>
                  <div
                    className={`${style['size-group']} ${
                      tv.length > 6 ? style['size-group-scroll'] : ''
                    }`}
                    ref={scrollRef}
                    id="size-group"
                    onScrollCapture={() => handleScroll(scrollRef.current.scrollTop)}
                  >
                    {tv && tv.map((tvItem) => (
                      <div
                        key={tvItem.id}
                        className={`${style['size-item']} 
                      ${selectTVIndex === tvItem.id ? style['size-active'] : ''} 
                      `}
                        onTouchStart={() => setIsMoving(false)}
                        onTouchEnd={() => changeSize(tvItem)}
                      >
                        {tvItem.size === 0 ? t('sideBar_component.no_tv') : tvItem.size}
                        {tvItem.id !== '0' && t('sideBar_component.inch')}
                      </div>
                    ))}
                    <div
                      className={style['right-btn']}
                      style={{ display: tv.length >= 6 ? 'block' : 'none' }}
                      onTouchStart={() => {
                        document.getElementById('size-group').scrollTo({
                          left: document.getElementById('size-group').offsetWidth,
                          behavior: 'smooth',
                        });
                      }}
                    >
                      <img src={rightArrow} alt="" />
                    </div>
                  </div>
                  <div className={style['group-arrow']} />
                </div>
                )}
              </div>
            ))}
          {/* <div className={style['down-arrow']}>
            <img src={rightArrow} alt="" className={style['down-arrow']} />
          </div> */}
          <div
            className={settingsVisible ? style.settings : style.settingsHide}
            onTouchStart={() => {
              setSettingsVisible(!settingsVisible);
              dispatch({ type: 'setWallVisible', payload: false });
              dispatch({ type: 'setGroundVisible', payload: false });
              dispatch({ type: 'setTvVisible', payload: false });
            }}
          >
            <img src={!settingsVisible ? SettingsIcon : downArrow} alt="" />
          </div>
        </div>
      }


    </div>
  );
}

SideBar.propTypes = {
  data: propTypes.oneOfType([propTypes.shape]),
  handleClick: propTypes.func,
  showFunction: propTypes.bool,
};

SideBar.defaultProps = {
  data: {},
  handleClick() {},
  showFunction: false,
};

export default SideBar;
