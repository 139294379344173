/*
 * @description: Route store, unified introduction pages
 * @Author: Zhanyu Shen
 * @Date: 2021-07-06 11:34:28
 * @LastEditors: Xiaohua Zhu
 * @LastEditTime: 2022-04-22 17:00:23
 */
import { lazy } from 'react';
import SideBar from '@/components/SideBar/sideBar';

const Detail = lazy(() => import('@/pages/details/details'));
const SchemeList = lazy(() => import('@/pages/schemeList/schemeList'));
const ComponentDetail = lazy(() => import('@/pages/componentDetail/componentDetail'));
const QADetail = lazy(() => import('@/pages/qASection/qADetail'));
const QASection = lazy(() => import('@/pages/qASection/qASection'));
const Adjustment = lazy(() => import('@/pages/adjustment/adjustment'));
const Assembly = lazy(() => import('@/pages/Serve/assembly'));
const Delivery = lazy(() => import('@/pages/Serve/delivery'));
const ReturnPolicy = lazy(() => import('@/pages/Serve/returnPolicy'));
const WelCome = lazy(() => import('@/pages/welcome/welcome'));


const Home = lazy(() => import('@/pages/home'));
const Test = lazy(() => import('@/pages/test'));

const routerConfig = [
  {
    path: '/',
    component: WelCome,
    abTestUser: 'AUser',
  },
  {
    path: '/',
    component: SchemeList,
    abTestUser: 'BUser',
  },
  {
    path: '/home',
    component: Home,
  },
  {
    path: '/mcc',
    component: SchemeList,
  },
  {
    path: '/test',
    component: Test,
  },
  {
    path: '/pip',
    component: Detail,
  },
  {
    path: '/designList',
    component: Detail,
  },
  {
    path: '/qa',
    component: QASection,
  },
  {
    path: '/qadetail',
    component: QADetail,
  },
  {
    path: '/pipdetail',
    component: ComponentDetail,
  },
  {
    path: '/adjustment',
    component: Adjustment,
  },
  {
    path: '/sidebar',
    component: SideBar,
  },
  {
    path: '/delivery',
    component: Delivery,
  },
  {
    path: '/assembly',
    component: Assembly,
  },
  {
    path: '/return_policy',
    component: ReturnPolicy,
  },
];

export default routerConfig;
