/*
 * @description: Route generator
 * @Author: Zhanyu Shen
 * @Date: 2021-07-15 21:00:04
 * @LastEditors: Yehao Gao
 * @LastEditTime: 2022-05-13 11:09:42
 */
import React, { Suspense } from 'react';
// import PropTypes from 'prop-types';
import {
  Route, Switch, withRouter,
} from 'react-router-dom';
import propTypes from 'prop-types';
// import Page404 from '@/pages/page404';
import Store from '@/redux/store';
import routerConfig from './config';

// eslint-disable-next-line react/prop-types
function Routers({ abtest }) {
  let userType = abtest;
  console.log(abtest);
  if (process.env.REACT_APP_ENV === 'test') {
    userType = 'AUser';
  }

  const newUser = JSON.parse(localStorage.getItem('newUser'));
  // 如果是第一次进入应用 并且是A用户 设置不为新用户
  if (!newUser && userType === 'AUser') {
    localStorage.setItem('newUser', '[1]');
  }
  if (newUser?.includes(1)) {
    userType = 'BUser';
  }

  return (
    <Store>
      <Suspense fallback={<></>}>
        <Switch>
          {
            routerConfig.map((router) => ((router.abTestUser && router.abTestUser !== userType) ? null : (
              <Route
                exact={router.exact || true}
                key={router.path}
                path={router.path}
                component={router.component}
              />
            )))
          }
          {/* <Redirect path="/" to="/home" exact /> */}
          {/* <Route path="*" component={Page404} status={404} /> */}
          {/* <Redirect from="*" to="/404" /> */}

        </Switch>
      </Suspense>
    </Store>
  );
}

// Routers.propTypes = {
//   config: PropTypes.oneOfType([PropTypes.array]),
// };

// Routers.defaultProps = {
//   config: [],
// };

Routers.propTypes = {
  abtest: propTypes.string.isRequired,
};

export default withRouter(Routers);
