class DefaultModule {
    /**
     * @description home page
     * @param index the selected tab when you go back to home
     * @param data the json string data passed to tab at index
     * @param tiktok_cid TikTok advertising id
     * @param utm_source utm_source
     * @param utm_medium utm_medium
     * @param utm_campaign utm_campaign
     * @param utm_content utm_content
     * @param utm_term utm_term
     */
    static Home(params) {
        const pathObject = {
            app: {
                path: '/tab_index?index={{index}}&data={{data}}'
            },
            smp: {
                path: '/pages/index/index?utm_source={{utm_source}}&utm_medium={{utm_medium}}&utm_campaign={{utm_campaign}}&utm_content={{utm_content}}&utm_term={{utm_term}}&tiktok_cid={{tiktok_cid}}',
                isTab: true
            },
            web: {
                visitPath: '/'
            },
            params: {
                index: {
                    type: 'optional',
                    desc: 'the selected tab when you go back to home'
                },
                data: {
                    type: 'optional',
                    desc: 'the json string data passed to tab at index'
                },
                tiktok_cid: {
                    type: 'optional',
                    desc: 'TikTok advertising id'
                },
                utm_source: {
                    type: 'optional',
                    desc: 'utm_source'
                },
                utm_medium: {
                    type: 'optional',
                    desc: 'utm_medium'
                },
                utm_campaign: {
                    type: 'optional',
                    desc: 'utm_campaign'
                },
                utm_content: {
                    type: 'optional',
                    desc: 'utm_content'
                },
                utm_term: {
                    type: 'optional',
                    desc: 'utm_term'
                }
            }
        };
        pathObject.receivedParams = params;
        return pathObject;
    }
    /**
     * @description sharing page
     * @param link the full h5 link
     * @param isH5Card share the h5 link as H5 card rather than Miniprogram card, only app support
     * @param path the native path for smp、web、app
     * @param poster the poster that can be saved
     * @param imageUrl the poster that used for smp
     * @param title the title in wechat sharing
     * @param originImageUrl the original image to download
     * @param posterRatio the aspect ratio of poster
     * @param trackSource the share source，only used in app
     */
    static Share(params) {
        const pathObject = {
            app: {
                path: '@command_shareLink({"isH5Card":"{{isH5Card}}","link":"{{link}}","path":"{{path}}","poster":"{{poster}}","title":"{{title}}","imageUrl":"{{imageUrl}}","originImageUrl":"{{originImageUrl}}","posterRatio":"{{posterRatio}}","trackSource":"{{trackSource}}"})',
                version: '>=2.0.0'
            },
            smp: {
                path: '@command_shareLink({"link":"{{link}}","path":"{{path}}","poster":"{{poster}}","title":"{{title}}","originImageUrl":"{{originImageUrl}}","imageUrl":"{{imageUrl}}","posterRatio":"{{posterRatio}}"})'
            },
            web: {
                visitPath: '/sdk-command/share-link?link=@command_encode({{link}})&path=@command_encode({{path}})&title={{title}}'
            },
            params: {
                link: {
                    type: 'optional',
                    desc: 'the full h5 link'
                },
                isH5Card: {
                    type: 'optional',
                    desc: 'share the h5 link as H5 card rather than Miniprogram card, only app support'
                },
                path: {
                    type: 'optional',
                    desc: 'the native path for smp、web、app'
                },
                poster: {
                    type: 'required',
                    desc: 'the poster that can be saved'
                },
                imageUrl: {
                    type: 'optional',
                    desc: 'the poster that used for smp'
                },
                title: {
                    type: 'required',
                    desc: 'the title in wechat sharing'
                },
                originImageUrl: {
                    type: 'optional',
                    desc: 'the original image to download'
                },
                posterRatio: {
                    type: 'optional',
                    desc: 'the aspect ratio of poster'
                },
                trackSource: {
                    type: 'optional',
                    desc: 'the share source，only used in app'
                }
            }
        };
        pathObject.receivedParams = params;
        return pathObject;
    }
    /**
     * @description 新首页全部频道入口
     */
    static HomeChannels() {
        const pathObject = {
            app: {
                path: '/home_channels',
                version: '>=1.0.0'
            },
            smp: {
                path: 'unsupported'
            },
            web: {
                visitPath: 'unsupported'
            }
        };
        return pathObject;
    }
    /**
     * @description shopping cart page
     */
    static ShoppingCart() {
        const pathObject = {
            app: {
                path: '/shopping_cart',
                version: '>=1.4.0'
            },
            smp: {
                path: '/pages/shopping_cart/shopping_cart',
                isTab: true
            },
            web: {
                visitPath: '/pay/cart'
            }
        };
        return pathObject;
    }
    /**
     * @description add products to to shopping cart
     * @param products the stringfied json of products, e.g. JSON.stringfy([id: xxx, itemType: null | ART | VPT, quantity: xxx])
     */
    static AddProductsToShoppingCart(params) {
        const pathObject = {
            app: {
                path: '/add_multi_product_to_cart?products=@command_encode({{products}})',
                version: '>=1.4.0'
            },
            smp: {
                path: '/pages/shopping_cart_non_tab/shopping_cart_non_tab?products=@command_encode({{products}})'
            },
            web: {
                visitPath: '/add_multi_product_to_cart?products=@command_encode({{products}})'
            },
            params: {
                products: {
                    type: 'required',
                    desc: 'the stringfied json of products, e.g. JSON.stringfy([id: xxx, itemType: null | ART | VPT, quantity: xxx])'
                }
            }
        };
        pathObject.receivedParams = params;
        return pathObject;
    }
    /**
     * @description customer service chat page
     * @param channel the channel for customer service, only support 'pax', 'besta' and 'smp'
     * @param autoText the auto-filled text
     * @param configId the config id for customer service
     * @param articleNo ProductId or OrderInfo: jsonString(orderId={{orderId}}&ikeaOrderNO={{ikeaOrderNO}}&orderChannel={{orderChannel}})
     * @param type PRODUCT | ORDER
     * @param page page type
     * @param orderID order id only works for order detail page
     * @param intent pax billy besta kitchen
     */
    static CustomerService(params) {
        const pathObject = {
            app: {
                path: '/customer_service?channel={{channel}}&autoText=@command_encode({{autoText}})&configId={{configId}}&articleNo={{articleNo}}&type={{type}}&page={{page}}&orderID={{orderID}}&intent={{intent}}',
                version: '>=2.0.0'
            },
            smp: {
                path: '@command_h5path(/page/customer_service.html?channel={{channel}}&autoText={{autoText}}&configId={{configId}}&articleNo={{articleNo}}&type={{type}}&page={{page}}&orderID={{orderID}}&intent={{intent}})'
            },
            web: {
                visitPath: '/sdk-command/customer_service?channel={{channel}}&autoText=@command_encode({{autoText}})&configId={{configId}}&articleNo={{articleNo}}&type={{type}}&page={{page}}&orderID={{orderID}}&intent={{intent}}'
            },
            params: {
                channel: {
                    type: 'optional',
                    desc: 'the channel for customer service, only support \'pax\', \'besta\' and \'smp\''
                },
                autoText: {
                    type: 'optional',
                    desc: 'the auto-filled text'
                },
                configId: {
                    type: 'optional',
                    desc: 'the config id for customer service'
                },
                articleNo: {
                    type: 'optional',
                    desc: 'ProductId or OrderInfo: jsonString(orderId={{orderId}}&ikeaOrderNO={{ikeaOrderNO}}&orderChannel={{orderChannel}})'
                },
                type: {
                    type: 'optional',
                    desc: 'PRODUCT | ORDER'
                },
                page: {
                    type: 'optional',
                    desc: 'page type'
                },
                orderID: {
                    type: 'optional',
                    desc: 'order id only works for order detail page'
                },
                intent: {
                    type: 'optional',
                    desc: 'pax billy besta kitchen'
                }
            }
        };
        pathObject.receivedParams = params;
        return pathObject;
    }
    /**
     * @description claim a coupon by calling mbff api /coupon/claim
     * @param activityId activity id associated with the coupon
     */
    static CouponClaim(params) {
        const pathObject = {
            app: {
                path: '/default_module/coupon_claim?activityId={{activityId}}',
                version: '>=2.19.0'
            },
            smp: {
                path: 'https://ikeaapp/coupon_claim?activityId={{activityId}}'
            },
            web: {
                visitPath: 'unsupported'
            },
            params: {
                activityId: {
                    type: 'required',
                    desc: 'activity id associated with the coupon'
                }
            }
        };
        pathObject.receivedParams = params;
        return pathObject;
    }
    /**
     * @description go search page
     * @param searchWord 进入搜索结果的搜索词
     * @param displayWord 显示在搜索框里的内容
     * @param source 来源（埋点用）
     */
    static GoSearch(params) {
        const pathObject = {
            app: {
                path: '/default_module/goSearch?searchWord={{searchWord}}&displayWord={{displayWord}}&source={{source}}',
                version: '>=2.0.0'
            },
            smp: {
                path: '/pages/preSearch/preSearch'
            },
            web: {
                visitPath: '/guide/search'
            },
            params: {
                searchWord: {
                    type: 'optional',
                    desc: '进入搜索结果的搜索词'
                },
                displayWord: {
                    type: 'optional',
                    desc: '显示在搜索框里的内容'
                },
                source: {
                    type: 'optional',
                    desc: '来源（埋点用）'
                }
            }
        };
        pathObject.receivedParams = params;
        return pathObject;
    }
    /**
     * @description search page
     * @param query the search keyword
     * @param type the search type
     * @param fromSearch fromSearch
     */
    static Search(params) {
        const pathObject = {
            app: {
                path: '/search?keyword={{query}}&type={{type}}&fromSearch={{fromSearch}}'
            },
            smp: {
                path: '/pages/search/search?query={{query}}'
            },
            web: {
                visitPath: '/search/products?q={{query}}'
            },
            params: {
                query: {
                    type: 'required',
                    desc: 'the search keyword'
                },
                type: {
                    type: 'optional',
                    desc: 'the search type'
                },
                fromSearch: {
                    type: 'optional',
                    desc: 'fromSearch'
                }
            }
        };
        pathObject.receivedParams = params;
        return pathObject;
    }
    /**
     * @description search page by product group
     * @param group the search group name
     */
    static SearchProductGroup(params) {
        const pathObject = {
            app: {
                path: '/search?group={{group}}&displayName={{group}}'
            },
            smp: {
                path: '/pages/search/search?group={{group}}'
            },
            web: {
                visitPath: '/search/products?group={{group}}'
            },
            params: {
                group: {
                    type: 'required',
                    desc: 'the search group name'
                }
            }
        };
        pathObject.receivedParams = params;
        return pathObject;
    }
    /**
     * @description go to any h5 page
     * @param url the url to any page
     * @param needNavigation need NavigationBar, only app supported
     * @param topSafeArea need topSafeArea, only app supported
     * @param custNavBarType the custom navigation bar type (search...), only app supported
     * @param title the title for the navigation bar, only app supported
     * @param titleBarParams the params (stringfied json) for the navigation bar, only app supported
     * @param needLogin need login before loading h5, only app supported
     * @param physicalBack navigate back, only app supported
     */
    static H5(params) {
        const pathObject = {
            app: {
                path: '@command_h5path({{url}})&needNavigation={{needNavigation}}&needLogin={{needLogin}}&topSafeArea={{topSafeArea}}&custNavBarType={{custNavBarType}}&title={{title}}&titleBarParams={{titleBarParams}}&physicalBack={{physicalBack}}'
            },
            smp: {
                path: '@command_h5path({{url}})'
            },
            web: {
                visitPath: '/h5page/?url=@command_encode({{url}})',
                path: '{{url}}'
            },
            params: {
                url: {
                    type: 'required',
                    desc: 'the url to any page'
                },
                needNavigation: {
                    type: 'optional',
                    desc: 'need NavigationBar, only app supported'
                },
                topSafeArea: {
                    type: 'optional',
                    desc: 'need topSafeArea, only app supported'
                },
                custNavBarType: {
                    type: 'optional',
                    desc: 'the custom navigation bar type (search...), only app supported'
                },
                title: {
                    type: 'optional',
                    desc: 'the title for the navigation bar, only app supported'
                },
                titleBarParams: {
                    type: 'optional',
                    desc: 'the params (stringfied json) for the navigation bar, only app supported'
                },
                needLogin: {
                    type: 'optional',
                    desc: 'need login before loading h5, only app supported'
                },
                physicalBack: {
                    type: 'optional',
                    desc: 'navigate back, only app supported'
                }
            }
        };
        pathObject.receivedParams = params;
        return pathObject;
    }
    /**
     * @description store list
     */
    static StoreList() {
        const pathObject = {
            app: {
                path: '/store_list?target=flutter'
            },
            smp: {
                path: 'unsupported'
            },
            web: {
                visitPath: 'unsupported'
            }
        };
        return pathObject;
    }
    /**
     * @description go to any page
     * @param page any page
     */
    static AnyPage(params) {
        const pathObject = {
            app: {
                path: '{{page}}'
            },
            smp: {
                path: '{{page}}'
            },
            web: {
                visitPath: 'unsupported'
            },
            params: {
                page: {
                    type: 'required',
                    desc: 'any page'
                }
            }
        };
        pathObject.receivedParams = params;
        return pathObject;
    }
    /**
     * @description show a popup from bottom. it has title, description and button
     * @param titleKey dictionary key of title
     * @param descKey dictionary key of content
     * @param buttonText text of the button
     */
    static BottomPopup(params) {
        const pathObject = {
            app: {
                path: '/bottom_popup?titleKey={{titleKey}}&descKey={{descKey}}&buttonText={{buttonText}}'
            },
            smp: {
                path: 'unsupported'
            },
            web: {
                visitPath: 'unsupported'
            },
            params: {
                titleKey: {
                    type: 'optional',
                    desc: 'dictionary key of title'
                },
                descKey: {
                    type: 'optional',
                    desc: 'dictionary key of content'
                },
                buttonText: {
                    type: 'optional',
                    desc: 'text of the button'
                }
            }
        };
        pathObject.receivedParams = params;
        return pathObject;
    }
    /**
     * @description mine page
     */
    static Mine() {
        const pathObject = {
            app: {
                path: '/mine'
            },
            smp: {
                path: '/pages/mine/mine'
            },
            web: {
                visitPath: '/profile/myprofile'
            }
        };
        return pathObject;
    }
    /**
     * @description footprint page
     */
    static FootPrintPage() {
        const pathObject = {
            app: {
                path: '/foot_print_page'
            },
            smp: {
                path: '@command_h5path(/modules/content/universal/footprints.html)',
                version: '>=1.0.0'
            },
            web: {
                visitPath: 'unsupported'
            }
        };
        return pathObject;
    }
    /**
     * @description my favorite page
     */
    static MyFavorite() {
        const pathObject = {
            app: {
                path: '/my_favorite'
            },
            smp: {
                path: 'unsupported'
            },
            web: {
                visitPath: 'unsupported'
            }
        };
        return pathObject;
    }
    /**
     * @description address book page
     */
    static AddressBook() {
        const pathObject = {
            app: {
                path: '/address_book'
            },
            smp: {
                path: 'unsupported'
            },
            web: {
                visitPath: 'unsupported'
            }
        };
        return pathObject;
    }
    /**
     * @description 个人资料页
     */
    static Profile() {
        const pathObject = {
            app: {
                path: '/profile'
            },
            smp: {
                path: 'unsupported'
            },
            web: {
                visitPath: 'unsupported'
            }
        };
        return pathObject;
    }
    /**
     * @description 设置页
     */
    static SettingsPage() {
        const pathObject = {
            app: {
                path: '/settings_page'
            },
            smp: {
                path: 'unsupported'
            },
            web: {
                visitPath: 'unsupported'
            }
        };
        return pathObject;
    }
    /**
     * @description 账户安全页
     */
    static AccountSettingsPage() {
        const pathObject = {
            app: {
                path: '/account_settings_page'
            },
            smp: {
                path: 'unsupported'
            },
            web: {
                visitPath: 'unsupported'
            }
        };
        return pathObject;
    }
}
export default DefaultModule;
