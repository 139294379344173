/*
 * @FilePath: \besta-online-web\src\redux\reducers\mccList.js
 * @Author: yehao Gao
 * @Date: 2021-08-02 11:22:22
 * @LastEditors: Yehao Gao
 * @LastEditTime: 2022-03-25 16:01:26
 */

const mccListAction = {
  setViewModel: (state, payload) => ({
    ...state,
    viewModel: payload,
  }),

  setAlterNativeNum: (state, payload) => ({
    ...state,
    alternativeNum: payload,
  }),

  setDetailInfo: (state, payload) => ({
    ...state,
    detailInfo: { ...payload },
  }),

  setCombinationVisible: (state, payload) => ({
    ...state,
    combinationVisible: payload,
  }),

  setBestList: (state, payload) => ({
    ...state,
    bestList: [...payload],
  }),
  setSchemeHeadZIndex: (state, payload) => ({
    ...state,
    schemeHeadZIndex: payload,
  }),
  setUserId: (state, payload) => ({
    ...state,
    userId: payload,
  }),
  setTemplateId: (state, payload) => ({
    ...state,
    templateId: payload,
  }),
  setTemplateType: (state, payload) => ({
    ...state,
    templateType: payload,
  }),
  setOutStockProducts: (state, payload) => ({
    ...state,
    outStockProducts: payload,
  }),
  setDesignCode: (state, payload) => ({
    ...state,
    designCode: payload,
  }),
  setPriceOrderIndex: (state, payload) => ({
    ...state,
    priceOrderIndex: [...payload],
  }),

  setInstallMethodIndex: (state, payload) => ({
    ...state,
    installMethodIndex: [...payload],
  }),

  setShapeTabTitle: (state, payload) => ({
    ...state,
    shapeTabTitle: payload,
  }),
  setWidthTabTitle: (state, payload) => ({
    ...state,
    widthTabTitle: payload,
  }),
  refresh: (state, payload) => ({ // 加入备选后特殊情况下需要强制刷新mcclist和收藏list
    ...state,
    refresh: payload,
  }),
  openAlternative: (state, payload) => ({ // 从自己设计的电视柜pip页返回到plp值设为true用来打开收藏夹
    ...state,
    openAlternative: payload,
  }),
  isFromShoppingCart: (state, payload) => ({ // 从自己设计的电视柜pip页返回到plp值设为true用来打开收藏夹
    ...state,
    isFromShoppingCart: payload,
  }),
  isGoPLP: (state, payload) => ({ // 购物车链路下在pip页点x是否回plp
    ...state,
    isGoPLP: payload,
  }),
  isFromQRCode: (state, payload) => ({ // 扫码进的pip页面
    ...state,
    isFromQRCode: payload,
  }),
  pushFromAlternative: (state, payload) => ({ // 是否从收藏夹跳转到pip
    ...state,
    pushFromAlternative: payload,
  }),
};

export default mccListAction;
