/*
 * @FilePath: \besta-online-web\src\adaptPlatform\app\index.js
 * @Author: yehao Gao
 * @Date: 2021-08-03 17:30:14
 * @LastEditors: Zhanyu Shen
 * @LastEditTime: 2021-08-13 09:43:15
 */
import { getCookie } from '../../utils/index';

export const compareVersion = (version1, version2) => {
  const arr1 = version1.split('.');
  const arr2 = version2.split('.');

  const len = Math.max(arr1.length, arr2.length);
  for (let i = 0; i < len; i += 1) {
    let data1 = 0;
    let data2 = 0;
    if (i < arr1.length) {
      data1 = parseInt(arr1[i], 10);
    }
    if (i < arr2.length) {
      data2 = parseInt(arr2[i], 10);
    }

    if (data1 < data2) {
      return -1;
    }
    if (data1 > data2) {
      return 1;
    }
  }
  return 0;
};

export const SUPPORT_CART = compareVersion(getCookie('app_version') || '0', '2.3.0') >= 0;
export const SUPPORT_JS_BRIGE_IMAGE = compareVersion(getCookie('app_version') || '0', '2.4.0') >= 0;

// eslint-disable-next-line
export const appSaveImage = async (imageData) => {
  if (SUPPORT_JS_BRIGE_IMAGE) {
    try {
      return await window.flutter_inappwebview.callHandler('saveImage', { imageData });
    } catch (e) {
      console.log('save image error', e);
      return false;
    }
  } else {
    try {
      window.location.href = `https://ikeaapp/saveImage?data=${encodeURIComponent(imageData)}`;
    } catch (e) {
      console.log('old save image error', e);
      return false;
    }
  }
};
