/*
 * @FilePath: \besta-online-web\src\redux\reducers\details.js
 * @Author: Yehao Gao
 * @Date: 2022-02-08 14:12:42
 * @LastEditors: Yehao Gao
 * @LastEditTime: 2022-03-08 14:05:31
 * @Description: Description
 */
const detailsAction = {
  stockoutCardVisible: (state, payload) => ({ // 缺货提醒弹窗
    ...state,
    stockoutCardVisible: payload,
  }),
};

export default detailsAction;
