/*
 * @description: Do not edit
 * @Author: Zhanyu Shen
 * @Date: 2022-01-11 21:51:36
 * @LastEditors: Zhanyu Shen
 * @LastEditTime: 2022-06-09 16:37:26
 */
// 侧边栏固定位置，渲染区域向左移动50像素
export const MOVELEFTPIXEL = 50;

// 支腿横向位置偏移量
export const FIRSTANDSECONDOFFSET = 1.5;

// 顶板厚度，纵向偏移量，目前由于素材尺寸暂时定位0
export const TOPPANELHEIGHT = 0;

// 地面偏移量，模拟地面上的柜子有透视效果
export const GROUNDDEVIATION = 13;

// 顶板配件 电视顶板 29581 顶板 10238
export const COMMONTOPPANELTYPECODE = '10238';

// 默认为推进式抽屉轨道
export const DEFAULTDRAWERTYPECODE = '31186';

// 玻璃门板的groupType为6
export const GLASSGROUPTYPE = 6;
/*
 * mcc调整操作类型
 */
export const MCCACTIONTYPE = {
  // 初始化渲染整个MCC
  INITMCC: 'InitMcc',
  // 用户点击渲染区内空白处
  CLICKSPACE: 'ClickSpace',
  // 用户在渲染区内选中某个门板
  CLICKUNIT: 'ClickUnit',
  // 用户点击功能分区按钮
  FUNCTIONAL: 'Functional',
  // 用户切换墙面
  CHANGEWALL: 'ChangeWall',
  // 用户切换地面
  CHANGEFLOOR: 'ChangeFloor',
  // 用户调整电视机
  CHANGETV: 'ChangeTV',
  // 用户调整框体
  CHANGEFRAME: 'ChangeFrame',
  // 用户调整门板
  CHANGEDOOR: 'ChangeDoor',
  // 用户调整抽屉轨道
  CHANGEDRAWER: 'ChangeDrawer',
  // 用户调整支腿
  CHANGELEG: 'ChangeLeg',
  // 用户调整顶板
  CHANGETOPPANEL: 'ChangeTopPanel',
};

export const MCCADJUSTMENTTABINDEX = {
  WIDTH: 'width',
  FRAME: 'frame',
  DOOR: 'door',
  LEG: 'leg',
  DRAWER: 'drawer',
  TOPPANEL: 'topPanel',
};

// 缺货类型
export const STOCKOUTTYPE = {
  1: 'frame',
  2: 'door',
  3: 'leg',
  4: 'drawer',
  5: 'topPanel',
  6: 'partition', // 隔板
  7: 'hangingStrip', // 挂条
  8: 'spupportLeg', // 隔板
  9: 'zygote', // 阻尼/推进合叶
  10: 'drawerFrame', // 抽屉框架
};

// mcc 可以调整的结构类型
export const MCCADJUSTMENTTYPE = {
  frame: '框体',
  door: '门板',
  leg: '支脚',
  drawer: '抽屉轨道',
  topPanel: '顶板',
  partition: '隔板',
  hangingStrip: '挂条',
  spupportLeg: '支撑腿',
  zygote: '阻尼/推进合叶',
  drawerFrame: '抽屉框架',
};

// 门板类型
export const DOORPANELTYPE = {
  highlight: '高光',
  matte: '哑光',
  function: '功能',
  woodColor: '木色',
  art: '艺术',
  glass: '玻璃',
};

// const SPACESCALE = 0.72; // 2.5D素材图片顶部侧边留白尺寸缩放比例

// 2.5D素材图片顶部侧边留白尺寸
export const SPACE25D = {
  DEEP20TOPSPACE: 8, // 20cm深柜子顶部留白
  DEEP20SIDESPACE: 4, // 20cm深柜子侧边留白
  DEEP40TOPSPACE: 18, // 40cm深柜子顶部留白
  DEEP40SIDESPACE: 9, // 40cm深柜子侧边留白
};

// localStorage存储的值
export const LOCALSTORAGE = {
  onShow: 'onShow', // ikea的onShow方法触发后设置的值，用来判断是从第三方页面回来，比如从原生登录页返回贝达后
  mccInfo: 'mccInfo', // 未登录时点击调整按钮后所记录的当前mcc信息（mccid，组合id），当登陆后返回调整页后所需
  addAlternativeId: 'addAlternativeId', // 未登录时点击收藏按钮后所记录的当前mccid，当登陆后返回调整页后所需
  addAlternative: 'addAlternative', // 在未登录情况下点击收藏，这时设置该值为'true'是为了登录回来后，触发收藏行为并且弹出收藏夹弹窗
  replaceType: 'replaceType', // 需要记录的方式，调整按钮点击跳转的登录还是收藏按钮点击跳转的登录页
  templateId: 'templateId', // 用于优化链路的mccid
  mccComboId: 'mccComboId', // 组合id
  templateType: 'templateType', // 用于优化链路,1表示普通mcc，3表示完成调整的
  innerHeight: 'innerHeight', // 除去导航条内容高度
  goAdjust: 'goAdjust', // 未登录点击去设计
};

// sessionStorage存储的值
export const SESSIONSTORAGE = {
  currentPlatform: 'currentPlatform', // 当前所在哪一端
  userId: 'userId', // 用户ID
  toBottom: 'toBottom',
  loginFromBesta: 'loginFromBesta', // 在app端是否是再besta的页面跳转到登录页
  templateId: 'templateId', // mccid
  temId: 'temId', // 用于在plp锚点的mccid
  designCode: 'designCode', // 设计码
  templateType: 'templateType', // 1表示普通mcc，3表示完成调整的
};

// 标尺画布需要比摆柜子的div大 宽度增加70  高度增加30
export const MARKINGLINEEXTRASIZE = { width: 70, height: 30 };

// 标尺各图标基准参数
export const MARKINGLINEBASEPARAM = {
  endpointLineLength: 6, // 标尺端点竖线长度
  endpointLineThickness: 1.2, // 标尺端点竖线厚度
  hightCornerOfBlackSquare: 14, // 标尺黑色底块厚度
  cornerOfBlackSquareExtra: 10, // 标尺黑色底块比文字内容多的边距
  lineThickness: 1.2, // 标尺线线宽
  fontSize: 10, // 尺寸内容字体大小
};

// 渲染MCC时作为参照物的门，门高2.2米 = 220
export const REFERENCEDOORHEIGHT = 220;

// 去除支腿 选项key
export const DISABLE_CIRCULAR_LEG = 'disable_circular_leg';
// 去除顶板 选项key
export const DISABLE_CIRCULAR_TOPPANEL = 'disable_circular_toppanel';
