/*
 * @FilePath: \besta-online-web\src\redux\store.js
 * @Author: Zhanyu Shen
 * @Date: 2021-07-06 11:06:25
 * @LastEditors: zhen Guo
 * @LastEditTime: 2021-07-30 15:10:43
 */
import PropTypes from 'prop-types';
import React, { createContext } from 'react';
import Reducer from './reducer';

export const AppContext = createContext();

/**
 * @description: 仓库
 * @param {*} children
 * @return {*}
 * @author: Yongchao Wang
 */
function Store({ children }) {
  const { storeState, dispatch } = Reducer();

  return (
    <AppContext.Provider value={{ storeState, dispatch }}>
      { children }
    </AppContext.Provider>
  );
}
Store.propTypes = {
  children: PropTypes.element,
};

Store.defaultProps = {
  children: (<div />),
};


export default Store;

