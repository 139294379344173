/*
 * @description: Besta MCC行为事件类，包含调整MCC框体, 门板, 支腿, 抽屉, 顶板，以及渲染见面的其他操作
 * @Author: Zhanyu Shen
 * @Date: 2022-01-12 14:16:13
 * @LastEditors: Zhanyu Shen
 * @LastEditTime: 2022-04-20 13:48:20
 */
import { defineProperty } from '@/utils/index';
import { DEFAULTDRAWERTYPECODE } from '@/utils/const';
import {
  getFrameColors, getDoorColors, getDoorColorsWithId, getTopPanelRender,
} from '@/apis/accessories';
import { cloneDeep } from 'lodash';
import BestaMccBaseRender from './BestaMccBaseRender';

export default class BestaMccActionEvent extends BestaMccBaseRender {
  /**
   * @description: Besta MCC行为事件类，包含调整MCC框体, 门板, 支腿, 抽屉, 顶板，以及渲染见面的其他操作
   * @param {*}
   * @return {*}
   * @author: Zhanyu Shen
   */
  constructor() {
    super();
    // 用户正在单独调整颜色的门板
    this.selectDoors = null;
    // 用户调整过的门板数据
    this.changeDoorData = null;
    // 是否显示功能分区
    this.showFunction = false;
    // 加减支腿后改变的高度差
    this.changeTop = 0;
  }

  getNewTemplateJson() {
    return JSON.parse(JSON.stringify(this.templateJson));
  }

  /**
   * @description: 用户点击功能分区，进行显示或隐藏功能分区
   * @param {Boolean} flag 显示或隐藏
   * @return {*}
   * @author: Zhanyu Shen
   */
  onClickFunction(flag = false) {
    this.showFunction = flag;
  }

  /**
   * @description: 用户在渲染区内点击空白区域
   * @param {*}
   * @return {*}
   * @author: Zhanyu Shen
   */
  onClickSpace() {
    const newFrameData = cloneDeep(this.frameData);
    const tempAllDoorsComponentId = [];
    newFrameData.forEach((frame) => {
      if (frame.units.length > 0) {
        frame.units.forEach((unit) => {
          if (unit.doors.length > 0) {
            unit.doors.forEach((door) => {
              defineProperty(door, 'doorBorder', null);
              tempAllDoorsComponentId.push(door.componentId);
            });
          }
        });
      }
    });
    this.selectDoors = null;
    // this.onClickFunction(false);
    this.allDoorsComponentId = tempAllDoorsComponentId;
    this.frameData = newFrameData;
  }

  /**
   * @description: 用户在渲染区内选中某个门板
   * @param {*}
   * @return {*}
   * @author: Zhanyu Shen
   */
  onClickDoors(unitId) {
    const newFrameData = cloneDeep(this.frameData);
    const tempSelectedDoors = [];
    const tempSelectedDoorComponentIds = [];
    const tempAppearanceIds = [];
    newFrameData.forEach((frame) => {
      const tempSelectObj = { frameId: frame.id, unitIndex: -1, doors: [] };
      if (frame.units.length > 0) {
        frame.units.forEach((unit, index) => {
          let doorBorder = null;
          if (unit.doors.length > 0 && unit.id === unitId) {
            doorBorder = { border: '2px solid #FFD800', boxSizing: 'border-box' };
            tempSelectObj.unitIndex = index;
          }
          unit.doors.forEach((door) => {
            defineProperty(door, 'doorBorder', doorBorder);
            if (doorBorder) {
              tempSelectObj.doors.push(door.componentId);
              tempSelectedDoorComponentIds.push(door.componentId);
              tempAppearanceIds.push(door.appearanceId);
            }
          });
        });
      }
      if (tempSelectObj.unitIndex !== -1) { tempSelectedDoors.push(tempSelectObj); }
    });
    this.selectDoors = tempSelectedDoors;
    // this.onClickFunction(false);
    this.frameData = newFrameData;
    return { componentIds: tempSelectedDoorComponentIds, appearanceIds: tempAppearanceIds };
  }

  /**
   * @description: 用户切换墙面，需要更新墙面图片wallUrl和template json里的墙面code
   * @param {*} wall 切换的新墙面数据
   * @return {*}
   * @author: Zhanyu Shen
   */
  changeWall(wall) {
    if (wall && wall.id) {
      // change template json
      this.templateJson.basic.wallColor = wall.id;
      // change image
      this.wallImg = wall.img;
      // hidden Functional
      // this.onClickFunction(false);
    }
    return this.getNewTemplateJson();
  }

  /**
   * @description: 用户切换地面，需要更新地面图片floorUrl和template json里的地面code
   * @param {*} floor 切换的新地面数据
   * @return {*}
   * @author: Zhanyu Shen
   */
  changeFloor(floor) {
    if (floor && floor.id) {
      // change template json
      this.templateJson.basic.floorColor = floor.id;
      // change image
      this.floorImg = floor.img;
      // hidden Functional
      // this.onClickFunction(false);
    }
    return this.getNewTemplateJson();
  }

  /**
   * @description: 用户调整电视机大小，需要更新电视机selectTvSize和template json里的用户电视机尺寸code: userTvCode
   * @param {Object} tv 调整的电视机
   * @return {*}
   * @author: Zhanyu Shen
   */
  changeTV(tv) {
    if (tv && tv.id) {
      if (tv.contrast === 0) {
        // mcc可放最大电视机的scale / 所选电视机scale , scale为某尺寸电视相比82寸电视的缩放比, contrast为0取消电视机
        this.hasTV = false;
      } else {
        const defaultTvSize = this.tvData?.tvStyle;
        const scaleTvWidth = Number(defaultTvSize?.width?.slice(0, -2)) / tv.contrast;
        const scaleTvHeight = Number(defaultTvSize?.height?.slice(0, -2)) / tv.contrast;
        const changeTV = {
          width: `${scaleTvWidth}px`,
          height: `${scaleTvHeight}px`,
        };
        this.selectTvSize = changeTV;
        this.hasTV = true;
      }
      // this.onClickFunction(false);
      if (tv.id || tv.id === 0) {
        this.templateJson.basic.userTvCode = tv.id;
      }
    }
    return this.getNewTemplateJson();
  }

  /**
   * @description: 用户调整框体颜色，更新全部框体素材图片和template json
   * @param {String} colour 框体颜色
   * @param {Number} price 调整前的MCC总预估价格
   * @return {*}
   * @author: Zhanyu Shen
   */
  async changeFrame(colour, price) {
    let newPrice = price;
    const components = this.allFramesComponentId.join(',');
    const res = await getFrameColors(colour, components, this.mccVersion);
    if (res.data.success && res.data.data) {
      const tempFrames = {};
      const tempFramesComponentId = [];
      res.data.data.list.forEach((item) => {
        // componentId is oldComponentId
        tempFrames[item.componentId] = item;
        tempFramesComponentId.push(item.newComponentId);
      });
      // 更新框体样式
      const newFrameData = cloneDeep(this.frameData);
      const newChangeFrameData = {};
      newFrameData.forEach((frame) => {
        const newFrame = tempFrames[frame.componentId];
        if (newFrame) {
          if (!newChangeFrameData[frame.id]) {
            newChangeFrameData[frame.id] = newFrame;
          }
          defineProperty(frame, 'componentId', newFrame.newComponentId);
          defineProperty(frame, 'frameUrl', newFrame.materialUrl);
        }
      });
      this.frameData = newFrameData;

      // 更新框体json
      if (tempFramesComponentId.length > 0) {
        this.allFramesComponentId = tempFramesComponentId;
        this.templateJson.basic.frameColor = colour || '';
        this.templateJson.frames.forEach((frame) => {
          const newFrame = newChangeFrameData[frame.componentBasic.uuid];
          if (newFrame && newFrame.newComponentId) {
            defineProperty(frame.componentBasic, 'componentId', newFrame.newComponentId);
            defineProperty(frame.componentBasic, 'name', newFrame.name);
            defineProperty(frame.componentBasic, 'imageUrl', newFrame.materialUrl);
            defineProperty(frame.componentBasic, 'colorName', newFrame.colorName);
          }
        });
      }

      // change frame price
      if (res.data.data.price !== res.data.data.newPrice) {
        const priceDiff = res.data.data.newPrice - res.data.data.price;
        newPrice += priceDiff;
      }
    }
    this.onClickFunction(false);
    const newTemplateJson = this.getNewTemplateJson();
    return { newTemplateJson, newPrice };
  }

  /**
   * @description: 用户调整门板样式，分为两种：单独调整某个unit内门板和调整MCC内全部门板，
   * 更新对应门板素材图片预告价格和template json
   * @param {String} appearanceId 门板样式ID
   * @param {String} price 调整前的MCC总预估价格
   * @param {String} templateId MCC的后端标识ID
   * @return {*}
   * @author: Zhanyu Shen
   */
  async changeDoor(appearanceId, price, templateId = null) {
    let newPrice = price;
    let components = null;
    let changeAllDoor = true; // 是否调整所有门板
    const tempChangeDoorData = this.changeDoorData ? cloneDeep(this.changeDoorData) : {};
    // 单独换单元内的门板颜色
    if (this.selectDoors && this.selectDoors.length > 0) {
      changeAllDoor = false;
      // 获取所有选中门板的componentId集合，拼接成字符串
      components = this.selectDoors.reduce(
        (allComponents, door) => `${allComponents},${door.doors.join(',')}`, '',
      );
      components = components.substr(1); // 去除componentId字符串最后一个逗号
      const res = await getDoorColors(appearanceId, components, this.mccVersion);
      if (res.data.success && res.data.data && res.data.data.list) {
        const tempDoors = {};
        res.data.data.list.forEach((item) => {
          // 以componentId为key，将新门板数据存入临时对象
          tempDoors[item.oldComponentId] = { ...item, appearanceId };
        });
        const newFrameData = cloneDeep(this.frameData);
        const selectDoorsCopy = cloneDeep(this.selectDoors);
        selectDoorsCopy.forEach((item) => {
          // 查找选中门板所在的框体
          const selectFrameIndex = newFrameData.findIndex((frame) => frame.id === item.frameId);
          if (selectFrameIndex !== -1 && newFrameData[selectFrameIndex].units.length > 0) {
            // 获取选中门板所在框体对应的单元
            const unit = newFrameData[selectFrameIndex].units[item.unitIndex];
            if (unit && unit.doors.length > 0) {
              const newDoorComponentIds = [];
              newFrameData[selectFrameIndex].units[item.unitIndex].doors.forEach((door) => {
                // 更新对应位置门板信息
                const newDoor = tempDoors[door.componentId];
                if (newDoor && newDoor.newComponentId) {
                  newDoorComponentIds.push(newDoor.newComponentId);
                  // 门板ID构成要素为: frameID-unitIndex-doorIndex，框体ID-所在单元位置-单元内门板位置，
                  // 所以这里的newDoor数据会替换相同位置的oldDoor数据
                  tempChangeDoorData[door.id] = newDoor;
                  defineProperty(door, 'componentId', newDoor.newComponentId);
                  // image url添加random，使浏览器强制刷新图片
                  defineProperty(door, 'doorUrl', `${newDoor.materialUrl}?random=${Math.random()}`);
                  defineProperty(door, 'appearanceId', appearanceId);
                } else {
                  newDoorComponentIds.push(door.componentId);
                }
              });
              if (newDoorComponentIds.length > 0) {
                // 更新所有选中门板的componentId
                defineProperty(item, 'doors', newDoorComponentIds);
              }
            }
          }
        });
        // change door price
        if (res.data.data.oldPrice !== res.data.data.newPrice) {
          const priceDiff = res.data.data.newPrice - res.data.data.oldPrice;
          newPrice += priceDiff;
        }
        this.selectDoors = selectDoorsCopy;
        this.frameData = newFrameData;
        this.changeDoorData = tempChangeDoorData;
        this.changeDoorJson();
      }
    } else {
      // 换所有门板颜色
      // 获取所有门板componentId集合字符串. 注意在上面if分支中没有去更新allDoorsComponentId，是因为换所有门板必须在没有selectDoors情况下
      // 在点击渲染区空白处（see onClickSpace）时，会取消选中门板并更新allDoorsComponentId
      components = this.allDoorsComponentId.join(',');
      let res = null;
      if (templateId) {
        // 切换宽度，套用上一个MCC所选门板样式，需要给接口提供ID，后端查询appearanceId是否在对应MCC门板聚合结果中
        res = await getDoorColorsWithId(appearanceId, components, templateId, this.mccVersion);
      } else {
        res = await getDoorColors(appearanceId, components, this.mccVersion);
      }
      if (res && res.data.success && res.data.data && res.data.data.list) {
        const tempDoors = {};
        const tempDoorsComponentId = [];
        res.data.data.list.forEach((item) => {
          tempDoors[item.oldComponentId] = { ...item, appearanceId };
          // 如何没有可替换掉门板，保留oldComponentId
          tempDoorsComponentId.push(item.newComponentId || item.oldComponentId);
        });
        const newFrameData = cloneDeep(this.frameData);
        newFrameData.forEach((frame) => {
          if (frame.units.length > 0) {
            frame.units.forEach((unit) => {
              unit.doors.forEach((door) => {
                const newDoor = tempDoors[door.componentId];
                if (newDoor && newDoor.newComponentId) {
                  // 门板ID构成要素为: frameID-unitIndex-doorIndex，框体ID-所在单元位置-单元内门板位置，
                  // 所以这里的newDoor数据会替换相同位置的oldDoor数据
                  tempChangeDoorData[door.id] = newDoor;
                  defineProperty(door, 'componentId', newDoor.newComponentId);
                  defineProperty(door, 'doorUrl', `${newDoor.materialUrl}?random=${Math.random()}`);
                  defineProperty(door, 'appearanceId', appearanceId);
                }
              });
            });
          }
        });
        // change door price
        if (res.data.data.oldPrice !== res.data.data.newPrice) {
          const priceDiff = res.data.data.newPrice - res.data.data.oldPrice;
          newPrice += priceDiff;
        }
        this.frameData = newFrameData;
        this.allDoorsComponentId = tempDoorsComponentId;
        this.changeDoorData = tempChangeDoorData;
        this.changeDoorJson();
      }
    }
    this.onClickFunction(false);
    const newTemplateJson = this.getNewTemplateJson();
    return {
      newTemplateJson, newPrice, doorAppearanceIds: this.templateJson.basic.doorAppearanceIds, changeAllDoor,
    };
  }

  /**
   * @description: 用户调整门板样式, 更新template json
   * @param {*}
   * @return {*}
   * @author: Zhanyu Shen
   */
  changeDoorJson() {
    const tempAllDoorsComponentId = [];
    const tempAllDoorAppearanceIds = [];
    const tempAllDoorColors = [];
    this.templateJson.frames.forEach((frame) => {
      const tempUnits = [];
      if (frame.units.length > 0) {
        frame.units.forEach((unit, unitIndex) => {
          const tempUnit = { door: [] };
          if (unit.height) { tempUnit.height = unit.height; }
          unit.door.forEach((door, doorIndex) => {
            const tempDoor = { ...door };
            // 从changeDoorData里根据门板ID获取对应的调整后新门板数据
            const tempChangeDoor = this.changeDoorData[
              `${frame.componentBasic.uuid}-${unitIndex}-${doorIndex}`];
            // 更新对应门板数据
            if (tempChangeDoor && tempChangeDoor.newComponentId) {
              tempDoor.componentBasic.componentId = tempChangeDoor.newComponentId;
              tempDoor.componentBasic.name = tempChangeDoor.name;
              tempDoor.componentBasic.imageUrl = tempChangeDoor.materialUrl;
              tempDoor.componentBasic.colorName = tempChangeDoor.colorName;
              tempDoor.componentBasic.appearanceId = tempChangeDoor.appearanceId;
              tempAllDoorsComponentId.push(tempChangeDoor.newComponentId);
              tempAllDoorAppearanceIds.push(tempChangeDoor.appearanceId);
              if (!tempAllDoorColors.includes(tempChangeDoor.colorName)) {
                tempAllDoorColors.push(tempChangeDoor.colorName);
              }
            } else {
              tempAllDoorsComponentId.push(door.componentBasic.componentId);
              tempAllDoorAppearanceIds.push(door.componentBasic.appearanceId);
              if (!tempAllDoorColors.includes(door.componentBasic.colorName)) {
                tempAllDoorColors.push(door.componentBasic.colorName);
              }
            }
            tempUnit.door.push(tempDoor);
          });
          tempUnits.push(tempUnit);
        });
      }
      if (tempUnits.length > 0) {
        defineProperty(frame, 'units', tempUnits);
      }
    });
    if (this.changeDoorData) {
      this.templateJson.basic.doorComponentIds = tempAllDoorsComponentId;
      this.templateJson.basic.doorAppearanceIds = tempAllDoorAppearanceIds;
      this.templateJson.basic.doorColors = tempAllDoorColors;
    }
  }

  /**
   * @description: 用户调整抽屉轨道，更新预估价格和template json
   * @param {Object} drawer 调整的抽屉轨道
   * @param {Number} drawerUnitPrice 调整前的抽屉轨道单价
   * @param {Number} price 调整前的MCC总预估价格
   * @return {*}
   * @author: Zhanyu Shen
   */
  changeDrawer(drawer, drawerUnitPrice, price) {
    let newPrice = price;
    if (drawer) {
      // 更新预估价格
      const currentDrawerUnitPrice = Number(drawer.price);
      const originalDrawerPrice = this.drawerQuantity * drawerUnitPrice;
      const currentDrawerPrice = this.drawerQuantity * currentDrawerUnitPrice;
      newPrice += (currentDrawerPrice - originalDrawerPrice);

      // 如果MCC有抽屉, 更新template json
      if (this.templateJson.basic.drawerFlag) {
        this.templateJson.basic.drawerTypeCode = drawer.id ? drawer.id.toString() : DEFAULTDRAWERTYPECODE;
      }
    }
    this.onClickFunction(false);
    const newTemplateJson = this.getNewTemplateJson();
    return { newTemplateJson, newPrice };
  }

  /**
   * @description: 用户调整支腿，更新支腿样式和template json
   * @param {Object} leg 调整的支腿
   * @param {Number} legUnitPrice 调整前的旧支腿单价
   * @param {Number} price 调整前的MCC总预估价格
   * @return {*} { newTemplateJson, newPrice }
   * @author: Zhanyu Shen
   */
  changeLeg(leg, price) {
    const newPrice = price;
    if (leg) {
      // 更新支腿样式
      // newPrice = this.changeLegStyles(leg, legUnitPrice, price);
      this.changeJsonAxisYByLeg(leg);
      this.onClickFunction(false);
    }
    const newTemplateJson = this.getNewTemplateJson();
    return { newTemplateJson, newPrice };
  }

  /**
   * @description: 用户调整支腿会影响底柜框体位置
   * @param {*} newLeg 调整的支腿
   * @param {*} hasLeg 原先有支腿（该MCC可以添加支腿且admin那设置了初始支腿）
   * @param {*} heightDiff 高度变化差值，heightDiff=0表示有上墙的柜子，不为0表示无上墙的柜子
   * @param {*} legMobileHeight 原支腿Y坐标比例
   * @param {*} originScreenshotHeight 含有顶柜留白和电视机的整体MCC高
   * @return {*}
   * @author: Zhanyu Shen
   */
  changeFrameJsonAxisYByLeg(newLeg, hasLeg, heightDiff, legMobileHeight, originScreenshotHeight) {
    // 调整底柜框体高度坐标
    this.templateJson.frames.forEach((frame) => {
      if (this.bottomFrames.includes(frame.componentBasic.uuid)) {
        if (!hasLeg && !newLeg.isDisable) {
          // 加支腿，mcc原先无支腿并且用户添加了支腿
          let newFrameMobileY = null;
          let newFrameMobileH = null;
          if (heightDiff === 0) {
            // 有上墙的柜子（size不变）底柜Y坐标比例-支腿Y坐标比例
            newFrameMobileY = frame.componentBasic.mobileProportion.y - legMobileHeight;
          } else if (heightDiff !== 0) {
            // 无上墙的柜子（size增加）
            // (原底柜Y坐标比例*saveScreenshotSize.height)/(saveScreenshotSize.height+支腿高度)
            newFrameMobileY = (frame.componentBasic.mobileProportion.y * originScreenshotHeight)
            / (originScreenshotHeight + this.originLegHeigth);
            newFrameMobileH = (frame.componentBasic.mobileProportion.h * originScreenshotHeight)
            / (originScreenshotHeight + this.originLegHeigth);
          }
          const newFrameY = frame.componentBasic.proportion.y - this.originLegHeigth;
          if (newFrameMobileY !== null) {
            // 更新高度比例数据
            defineProperty(frame.componentBasic.mobileProportion, 'y', newFrameMobileY);
          }
          if (newFrameMobileH !== null) {
            defineProperty(frame.componentBasic.mobileProportion, 'h', newFrameMobileH);
          }
          // 更新高度坐标数据
          defineProperty(frame.componentBasic.proportion, 'y', newFrameY);
        } else if (hasLeg && newLeg.isDisable) {
          // 减支腿，mcc原先有支腿并且用户去掉了支腿
          let newFrameMobileY = null;
          let newFrameMobileH = null;
          if (heightDiff === 0) {
            // 有上墙的柜子（size不变），底柜Y坐标比例+支腿Y坐标比例
            newFrameMobileY = frame.componentBasic.mobileProportion.y + legMobileHeight;
          } else if (heightDiff !== 0) {
            // 无上墙的柜子（size增加）
            // (原底柜Y坐标比例*saveScreenshotSize.height)/(saveScreenshotSize.height-支腿高度)
            newFrameMobileY = (frame.componentBasic.mobileProportion.y * originScreenshotHeight)
            / (originScreenshotHeight - this.originLegHeigth);
            newFrameMobileH = (frame.componentBasic.mobileProportion.h * originScreenshotHeight)
            / (originScreenshotHeight - this.originLegHeigth);
          }
          const newFrameY = frame.componentBasic.proportion.y + this.originLegHeigth;
          if (newFrameMobileY !== null) {
            defineProperty(frame.componentBasic.mobileProportion, 'y', newFrameMobileY);
          }
          if (newFrameMobileH !== null) {
            defineProperty(frame.componentBasic.mobileProportion, 'h', newFrameMobileH);
          }
          defineProperty(frame.componentBasic.proportion, 'y', newFrameY);
        }
      }
    });
  }

  /**
   * @description: 用户调整支腿会影响电视机位置
   * @param {*} newLeg 调整的支腿
   * @param {*} hasLeg 原先有支腿（该MCC可以添加支腿且admin那设置了初始支腿）
   * @param {*} heightDiff 高度变化差值，heightDiff=0表示有上墙的柜子，不为0表示无上墙的柜子
   * @param {*} legMobileHeight 原支腿Y坐标比例
   * @param {*} originScreenshotHeight 含有顶柜留白和电视机的整体MCC高
   * @return {*}
   * @author: Zhanyu Shen
   */
  changeTVJsonAxisYByLeg(newLeg, hasLeg, heightDiff, legMobileHeight, originScreenshotHeight) {
    // change tv Y
    // admin那是否给mcc设置了电视机
    if (this.templateJson.propping && this.templateJson.propping.length > 0) {
      const tvObj = this.templateJson.propping[0]; // 当前propping里只有电视机，所以数组第一个默认为电视机
      if (!hasLeg && !newLeg.isDisable) {
        // 加支腿，mcc原先无支腿并且用户添加了支腿
        let newTVMobileY = null;
        if (heightDiff === 0) {
          // 有上墙的柜子（size不变），电视Y坐标比例-支腿Y坐标比例
          newTVMobileY = tvObj.mobileProportion.y - legMobileHeight;
        } else if (heightDiff !== 0) {
          // 无上墙的柜子（size增加）且mcc无TV
          // (原电视Y坐标比例*saveScreenshotSize.height)/(saveScreenshotSize.height+支腿高度)
          newTVMobileY = (tvObj.mobileProportion.y * originScreenshotHeight)
            / (originScreenshotHeight + this.originLegHeigth);
        }
        const newTVY = tvObj.proportion.y - this.originLegHeigth;
        if (newTVMobileY !== null) {
          this.templateJson.propping[0].mobileProportion.y = newTVMobileY;
        }
        this.templateJson.propping[0].proportion.y = newTVY;
      } else if (hasLeg && newLeg.isDisable) {
        // 减支腿，mcc原先有支腿并且用户去掉了支腿
        let newTVMobileY = null;
        if (heightDiff === 0) {
          // 有上墙的柜子（size不变），电视Y坐标比例+支腿Y坐标比例
          newTVMobileY = tvObj.mobileProportion.y + legMobileHeight;
        } else if (heightDiff !== 0) {
          // 无上墙的柜子（size增加）且mcc无TV
          // (原电视Y坐标比例*saveScreenshotSize.height)/(saveScreenshotSize.height-支腿高度)
          newTVMobileY = (tvObj.mobileProportion.y * originScreenshotHeight)
            / (originScreenshotHeight - this.originLegHeigth);
        }
        const newTVY = tvObj.proportion.y + this.originLegHeigth;
        if (newTVMobileY !== null) {
          this.templateJson.propping[0].mobileProportion.y = newTVMobileY;
        }
        this.templateJson.propping[0].proportion.y = newTVY;
      }
    }
  }

  /**
   * @description: 用户调整支腿会影响电视机位置
   * @param {*} newLeg 调整的支腿
   * @param {*} hasLeg 原先有支腿（该MCC可以添加支腿且admin那设置了初始支腿）
   * @param {*} heightDiff 高度变化差值，heightDiff=0表示有上墙的柜子，不为0表示无上墙的柜子
   * @param {*} legMobileHeight 原支腿Y坐标比例
   * @param {*} originScreenshotHeight 含有顶柜留白和电视机的整体MCC高
   * @return {*}
   * @author: Zhanyu Shen
   */
  changeTopPanelJsonAxisYByLeg(newLeg, hasLeg, heightDiff, legMobileHeight, originScreenshotHeight) {
    // change topPanel Y
    this.templateJson.basic.topPanel.forEach((panelGroup) => {
      if (panelGroup.componentBasic && panelGroup.componentBasic.length > 0) {
        // 判断某个顶板组是否在底柜上
        const isOnBottomFrames = panelGroup.frames
          ? panelGroup.frames.filter((frame) => this.bottomFrames.includes(frame)).length > 0 : false;
        if (isOnBottomFrames) {
          panelGroup.componentBasic.forEach((panel) => {
            if (!hasLeg && !newLeg.isDisable) {
              // 加支腿，mcc原先无支腿并且用户添加了支腿
              let newPanelY = null;
              if (heightDiff === 0) {
                // 有上墙的柜子（size不变），顶板Y坐标比例-支腿Y坐标比例
                newPanelY = panel.mobileProportion.y - legMobileHeight;
              } else if (heightDiff !== 0) {
                // 无上墙的柜子（size增加）
                // (原顶板Y坐标比例*saveScreenshotSize.height)/(saveScreenshotSize.height+支腿高度)
                newPanelY = (panel.mobileProportion.y * originScreenshotHeight)
                / (originScreenshotHeight + this.originLegHeigth);
              }
              const newY = panel.proportion.y - this.originLegHeigth;
              if (newPanelY !== null) {
                defineProperty(panel.mobileProportion, 'y', newPanelY);
              }
              defineProperty(panel.proportion, 'y', newY);
            } else if (hasLeg && newLeg.isDisable) {
              // 减支腿，mcc原先有支腿并且用户去掉了支腿
              let newPanelY = null;
              if (heightDiff === 0) {
                // 有上墙的柜子（size不变）或者 无上墙的柜子且mcc有TV，顶板Y坐标比例+支腿Y坐标比例
                newPanelY = panel.mobileProportion.y + legMobileHeight;
              } else if (heightDiff !== 0) {
                // 无上墙的柜子（size增加）且mcc无TV
                // (原顶板Y坐标比例*saveScreenshotSize.height)/(saveScreenshotSize.height-支腿高度)
                newPanelY = (panel.mobileProportion.y * originScreenshotHeight)
                / (originScreenshotHeight - this.originLegHeigth);
              }
              const newY = panel.proportion.y + this.originLegHeigth;
              if (newPanelY !== null) {
                defineProperty(panel.mobileProportion, 'y', newPanelY);
              }
              defineProperty(panel.proportion, 'y', newY);
            }
          });
        }
      }
    });
  }

  /**
   * @description: 用户调整支腿会影响底柜框体、支腿、电视机、顶板的Y坐标，需要对这些配件进行上移或下移，
   * 注意特殊支腿和普通支腿相互切换需要更改leg json数组，删除或添加相应支腿
   * @param {Object} newLeg 调整的支腿
   * @return {*}
   * @author: Zhanyu Shen
   */
  changeJsonAxisYByLeg(newLeg) {
    // 用户调整了支腿（新支腿不为NULL且该MCC可以添加支腿）
    const hasChangeLeg = !!(newLeg && this.templateJson.basic.legFlag);
    // 原先有支腿（该MCC可以添加支腿且admin那设置了初始支腿）
    const hasLeg = this.templateJson.basic.legFlag && this.templateJson.basic.hasLeg;
    // 用户没有调整支腿 或 原先没有支腿并去除支腿 都不需要更改框体支腿电视机顶板的坐标
    if (!hasChangeLeg || (!hasLeg && newLeg.isDisable)) { return; }
    // 上一步支腿类型，newLeg为当前选择的支腿，所以newLeg.specialFlag为当前支腿类型
    const previousLegSpecialFlag = this.templateJson.basic.legSpecialFlag || '0';
    // 原支腿Y坐标比例
    let legMobileHeight = 0;
    if (this.templateJson.basic.legs && this.templateJson.basic.legs.length > 0) {
      legMobileHeight = this.templateJson.basic.legs[0].componentBasic[0].mobileHeight || 0;
    }
    // 高度变化差值，heightDiff=0表示有上墙的柜子，不为0表示无上墙的柜子
    // 这里的this.templateJson.basic.size.height还是原先的旧值，在该方法的最后才会更新为this.size.height
    const heightDiff = Number(this.size.height) - Number(this.templateJson.basic.size.height);
    // 含有顶柜留白和电视机的整体MCC高
    const originScreenshotHeight = Number(this.templateJson.basic.saveScreenshotSize.height);

    // 如果更换了支腿，更新leg component info
    const newLegsData = [];
    this.templateJson.basic.legs.forEach((legGroup) => {
      const eachGroupLegs = [];
      if (legGroup.componentBasic && legGroup.componentBasic.length > 0) {
        const lastLegIndex = legGroup.componentBasic.length - 1;
        legGroup.componentBasic.forEach((leg, index) => {
          const tempLegObj = {
            componentId: newLeg.componentId || null,
            name: newLeg.name || null,
            typeCode: newLeg.typeCode || null,
            typeName: newLeg.typeName || null,
            imageUrl: newLeg.materialUrl || null,
          };
          // 选择了支腿（非取消项），且所选支腿类型不同于上一步类型
          if (!newLeg.isDisable && newLeg.specialFlag !== previousLegSpecialFlag) {
            switch (previousLegSpecialFlag) {
              case '0':
                // 原始支腿可共用，改为不可共用（previousLegSpecialFlag为0，那么newLeg.specialFlag为1）
                if (index === 0 || index === lastLegIndex) {
                  // 第一个支腿 和 最后一个支腿
                  eachGroupLegs.push({ ...leg, ...tempLegObj });
                } else {
                  // 底柜连接处需要两个支腿(一个支腿变两个)
                  eachGroupLegs.push({ ...leg, ...tempLegObj });
                  eachGroupLegs.push({ ...leg, ...tempLegObj });
                }
                break;
              case '1':
                // 原始为 NANNARP纳普 支腿，不可共用，改为可共用
                if (index === 0 || index === lastLegIndex) {
                  // 第一个支腿 和 最后一个支腿
                  eachGroupLegs.push({ ...leg, ...tempLegObj });
                } else if (index % 2 === 0) {
                  // 底柜连接处两个支腿变一个，取后一个支腿
                  eachGroupLegs.push({ ...leg, ...tempLegObj });
                }
                break;
              default:
                break;
            }
          } else {
            // 选择了取消支腿，或所选支腿类型同原先类型
            eachGroupLegs.push({ ...leg, ...tempLegObj });
          }
        });
      }
      newLegsData.push({ ...legGroup, componentBasic: eachGroupLegs });
    });
    this.templateJson.basic.legs = newLegsData;

    // 原先有支腿并调整支腿(添加支腿) 不需要调整底柜电视机顶板位置
    if (!(hasLeg && !newLeg.isDisable)) {
      this.changeFrameJsonAxisYByLeg(newLeg, hasLeg, heightDiff, legMobileHeight, originScreenshotHeight);
      this.changeTVJsonAxisYByLeg(newLeg, hasLeg, heightDiff, legMobileHeight, originScreenshotHeight);
      this.changeTopPanelJsonAxisYByLeg(newLeg, hasLeg, heightDiff, legMobileHeight, originScreenshotHeight);
    }
    // change mcc height
    // 有上墙的柜子，size不变; 没有上墙的柜子（都是底柜），改size和saveScreenshotSize
    if (heightDiff !== 0) {
      this.templateJson.basic.size.height = this.size.height;
      this.templateJson.basic.saveScreenshotSize.height = originScreenshotHeight + heightDiff;
    }
    // 更新是否有支腿标识
    this.templateJson.basic.hasLeg = !newLeg.isDisable;
    this.templateJson.basic.legComponentIds = newLeg.isDisable ? [] : [newLeg.componentId];
    // 如果新支腿的特殊类型标识为空，则使用原先的值
    this.templateJson.basic.legSpecialFlag = newLeg.specialFlag || previousLegSpecialFlag;
  }

  /**
   * @description: 用户调整顶板
   * @param {Object} newTopPanel 调整的顶板
   * @param {Number} topPanelPrice 调整前的旧顶板总价
   * @param {Number} price 调整前的MCC总预估价格
   * @return {*}
   * @author: Zhanyu Shen
   */
  async changeTopPanel(newTopPanel, topPanelPrice, price) {
    let newPrice = price;
    const newTopPanelData = cloneDeep(this.topPanelData);
    let display = null;
    if (newTopPanel.isDisable) {
      display = 'none';
    }
    if (!display) {
      // 临时存储用户调整后的新顶板数据
      const changeData = {};
      // 后端需要顶板code，宽度和componentId才能返回相同尺寸相同材质（材质分为木质顶板和玻璃顶板）的新顶板数据
      const sizeAndTypeLists = this.topPanelData.map((panel) => ({
        typeCode: panel.typeCode,
        width: Number(panel.width) * 10,
        componentId: panel.componentId,
      }));
      const { colorName } = newTopPanel;
      const res = await getTopPanelRender({ colorName, sizeAndTypeLists, mccVersion: this.mccVersion });
      let currentTotalPrice = 0;
      if (res.data.success && res.data.data && res.data.data.length > 0) {
        const newTopPanels = res.data.data;
        changeData.topPanelColorName = colorName;
        // 和后端约定好: 传入接口的顶板顺序和返回新顶板一致，即相同index的新顶板是旧顶板的替换对象
        newTopPanelData.forEach((topPanel, index) => {
          if (newTopPanels.length > 0) {
            const newPanel = newTopPanels[index] || null;
            defineProperty(topPanel, 'componentId', newPanel.componentId || null);
            defineProperty(topPanel, 'imageUrl',
              newPanel.materialUrl ? `${newPanel.materialUrl}?random=${Math.random()}` : null);
            defineProperty(topPanel.panelStyle, 'display', display);

            const currentPrice = Number(newPanel.price || 0);
            currentTotalPrice += currentPrice;

            changeData[topPanel.id] = {
              componentId: newPanel.componentId || null,
              name: newPanel.name || null,
              typeCode: newPanel.typeCode || topPanel.typeCode,
              typeName: newPanel.typeName || null,
              imageUrl: newPanel.materialUrl || null,
            };
          }
        });
      }
      this.topPanelData = newTopPanelData;
      newPrice += (currentTotalPrice - topPanelPrice);
      this.changeTopPanelJson(changeData);
      this.onClickFunction(false);
      const newTemplateJson = this.getNewTemplateJson();
      return { newTemplateJson, newTopPanelPrice: currentTotalPrice, newPrice };
    }
    newTopPanelData.forEach((topPanel) => {
      defineProperty(topPanel.panelStyle, 'display', display);
      defineProperty(topPanel, 'componentId', null);
    });
    this.topPanelData = newTopPanelData;
    newPrice -= topPanelPrice;
    this.changeTopPanelJson(null);
    this.onClickFunction(false);
    const newTemplateJson = this.getNewTemplateJson();
    return { newTemplateJson, newTopPanelPrice: 0, newPrice };
  }

  /**
   * @description: 用户调整顶板，更新顶板template json
   * @param {*} changeTopPanelData 调整的顶板数据
   * @return {*}
   * @author: Zhanyu Shen
   */
  changeTopPanelJson(changeTopPanelData) {
    const tempPanelComponentIds = [];
    if (changeTopPanelData) {
      this.templateJson.basic.hasTopPanel = true;
      this.templateJson.basic.topPanelColorName = changeTopPanelData.topPanelColorName;
      this.templateJson.basic.topPanel.forEach((panelGroup) => {
        const panelGroupId = panelGroup.uuid || 0;
        if (panelGroup.componentBasic && panelGroup.componentBasic.length > 0) {
          panelGroup.componentBasic.forEach((panel, topPanelIndex) => {
            const changePanel = changeTopPanelData[`${panelGroupId}-${topPanelIndex}`];
            if (changePanel) {
              defineProperty(panel, 'componentId', changePanel.componentId || null);
              defineProperty(panel, 'name', changePanel.name || null);
              defineProperty(panel, 'typeCode', changePanel.typeCode || null);
              defineProperty(panel, 'typeName', changePanel.typeName || null);
              defineProperty(panel, 'imageUrl', changePanel.imageUrl || null);
              // 如果所选颜色没有对应尺寸的顶板，componentId会为null
              if (changePanel.componentId) { tempPanelComponentIds.push(changePanel.componentId); }
            }
          });
        }
      });
    } else {
      this.templateJson.basic.hasTopPanel = false;
    }
    this.templateJson.basic.topPanelComponentIds = Array.from(new Set(tempPanelComponentIds));
  }
}
