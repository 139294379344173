/*
 * @description: 获取MCC配件
 * @Author: Zhanyu Shen
 * @Date: 2021-08-16 20:18:26
 * @LastEditors: Zhanyu Shen
 * @LastEditTime: 2022-04-29 10:33:49
 */
import request from '@/utils/request';

// 获取MCC配件--框体颜色
export function getMccFrameColors(templateId, zipcode) {
  return request({
    url: '/mcc/accessories/getFrameColors',
    method: 'GET',
    params: {
      templateId,
      zipcode,
    },
  });
}

// 获取初始化的门板细节图，如果mcc中有玻璃门板，只获取玻璃门板的图，否则是木门的图
export function getMccDoorAccessories(templateId, zipcode) {
  return request({
    url: '/mcc/accessories/doorStyle',
    method: 'GET',
    params: {
      templateId,
      zipcode,
    },
  });
}

// 根据选择的门板类型获取对应类型的所以细节图（用户选择某个门板时会刷新门板颜色）
export function getDoorStyleColors(templateId, componentIds, zipcode) {
  return request({
    url: '/mcc/accessories/doorStyleColors',
    method: 'GET',
    params: {
      templateId,
      componentIds,
      zipcode,
    },
  });
}

// 获取支脚素材图
export function getLegAccessories(templateId, zipcode, mccVersion) {
  return request({
    url: '/mcc/accessories/leg',
    method: 'GET',
    params: {
      templateId,
      zipcode,
      mccVersion,
    },
  });
}

// 获取抽屉轨道素材图
export function getDrawerTrackAccessories(templateId, zipcode) {
  return request({
    url: '/mcc/accessories/drawerTrack',
    method: 'GET',
    params: {
      templateId,
      zipcode,
    },
  });
}

// 获取玻璃顶板素材图
export function getTopPanelAccessories(tvBenchFlag, templateId, zipcode) {
  return request({
    url: '/mcc/accessories/topPanel',
    method: 'GET',
    params: {
      tvBenchFlag,
      templateId,
      zipcode,
    },
  });
}

// // 获取template json
// export function getTemplateJson(templateId, templateType, userId = 'NOTEXISTUSER') {
//   return request({
//     url: '/mcc/myDesign',
//     method: 'GET',
//     params: {
//       templateId,
//       templateType,
//       userId,
//       bu: 'cn',
//       language: 'zh',
//     },
//   });
// }

// 获取template json
export function getTemplateJson({
  templateId = '', templateType = '', userId = 'NOTEXISTUSER', widthType = null, mccComboId = null,
}) {
  return request({
    url: '/mcc/myDesign',
    method: 'GET',
    params: {
      templateId: templateId || null,
      templateType: templateType || null,
      userId,
      bu: 'cn',
      language: 'zh',
      widthType,
      mccComboId,
    },
  });
}

// 获取柜体2.5D素材图
export function getFrameColors(colour, components, mccVersion) {
  return request({
    url: '/mcc/accessories/frameColors',
    method: 'GET',
    params: {
      colour,
      components,
      mccVersion,
    },
  });
}

// 调整电视柜保存--上传图片
export function postImage(formData) {
  return request({
    url: '/image/saveImage',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  });
}

// 调整电视柜保存--上传图片
export function postUserDesign(data) {
  return request({
    url: '/mcc/saveUserDesign',
    method: 'POST',
    data,
  });
}

// 获取MCC配件--替换玻璃顶板
export function getTopPanelRender(data) {
  return request({
    url: '/mcc/accessories/topPanelRender',
    method: 'POST',
    data,
  });
}

// 获取门板2.5D素材图
export function getDoorColors(appearanceId, oldComponentIds, mccVersion) {
  return request({
    url: '/mcc/accessories/doorStyleRender',
    method: 'GET',
    params: {
      appearanceId,
      oldComponentIds,
      mccVersion,
    },
  });
}

// 获取门板2.5D素材图，切换宽度，套用上一个MCC所选门板样式，需要给接口提供ID，后端查询appearanceId是否在对应MCC门板聚合结果中
export function getDoorColorsWithId(appearanceId, oldComponentIds, templateId, mccVersion) {
  return request({
    url: '/mcc/accessories/doorStyleRender',
    method: 'GET',
    params: {
      appearanceId,
      oldComponentIds,
      templateId,
      mccVersion,
    },
  });
}
