class Account {
    /**
     * @description login page
     * @param redirectUri url to redirect after login
     * @param needToBeMember login and register as an IKEA member, only for smp
     * @param isIB ikea business or not
     */
    static Login(params) {
        const pathObject = {
            app: {
                path: '/CiamModule/login?canWechatLogin=true&isIB={{isIB}}',
                version: '>=2.0.0'
            },
            smp: {
                path: '/subpages/login/pages/wx_login/login?needToBeMember={{needToBeMember}}&isIB={{isIB}}'
            },
            sc: {
                path: '/pages/login/login?registerType={{needToBeMember}}&isIB={{isIB}}'
            },
            web: {
                visitPath: '/profile/login?redirect_uri=@command_encode({{redirectUri}})'
            },
            params: {
                redirectUri: {
                    type: 'optional',
                    desc: 'url to redirect after login'
                },
                needToBeMember: {
                    type: 'optional',
                    desc: 'login and register as an IKEA member, only for smp'
                },
                isIB: {
                    type: 'optional',
                    desc: 'ikea business or not'
                }
            }
        };
        pathObject.receivedParams = params;
        return pathObject;
    }
    /**
     * @description go to register as an IKEA member
     * @param storeId the storeId
     */
    static RegisterMember(params) {
        const pathObject = {
            app: {
                path: '@command_h5path(/modules/ikeacn-portal/universal/register-member.html?storeId={{storeId}}&CIAM_FAMILY_MEMBER=true&noLogin=true)&needLogin=true'
            },
            smp: {
                path: '/ciam/subpackage/pages/register/step1/step1?pageName=MEMBER_PAGE&fromChannel=SHOPPABLE_MINI_P&redirectTo=pages/h5page/h5page&pageType=page&registerChannel=SHOPPABLE_MINI_P&registerSource=OUT_STORE&registerStore=OUT_STORE&registerEnter=/subpages/login/pages/wx_login/login&enterPageType=page&storeCode={{storeId}}'
            },
            web: {
                visitPath: '/profile/new-member-quick-step'
            },
            params: {
                storeId: {
                    type: 'optional',
                    desc: 'the storeId'
                }
            }
        };
        pathObject.receivedParams = params;
        return pathObject;
    }
    /**
     * @description go to register as an IKEA member
     * @param phone the phone number
     * @param firstName the firstName
     */
    static RegisterMemberNative(params) {
        const pathObject = {
            app: {
                path: '/CiamModule/registerFamily?phone={{phone}}&firstName={{firstName}}'
            },
            smp: {
                path: 'https://ikeaapp/registerFamily'
            },
            web: {
                visitPath: '/profile/new-member-quick-step'
            },
            params: {
                phone: {
                    type: 'optional',
                    desc: 'the phone number'
                },
                firstName: {
                    type: 'optional',
                    desc: 'the firstName'
                }
            }
        };
        pathObject.receivedParams = params;
        return pathObject;
    }
}
export default Account;
