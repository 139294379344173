/*
 * @description: Besta MCC渲染类，包含MCC框体, 门板, 支腿, 抽屉, 顶板数据和样式
 * @Author: Zhanyu Shen
 * @Date: 2022-01-12 14:16:13
 * @LastEditors: Zhanyu Shen
 * @LastEditTime: 2022-04-21 14:45:05
 */
import { MOVELEFTPIXEL } from '@/utils/const';
import {
  wallProductDrawing, floorProductDrawing, TVSize,
} from '@/utils/enum';
import { cloneDeep } from 'lodash';

export default class BestaMccBaseRender {
  /**
   * @description: Besta MCC渲染类，包含MCC框体, 门板, 支腿, 抽屉, 顶板数据和样式
   * @return {*}
   * @author: Zhanyu Shen
   */
  constructor() {
    // 从后端获取的MCC结构JSON，该JSON里定义了柜子，门板，支腿，顶板的坐标和素材
    this.templateJson = null;
    // 移动端渲染区域宽
    this.mobileWidth = 0;
    // 移动端渲染区域高
    this.mobileHeight = 0;
    // MCC柜体的真实宽高尺寸（不包含顶柜留白和电视机，含有支腿高度）
    this.size = { width: 0, height: 0, deep: 0 };
    // 含有顶柜留白和电视机的MCC整体宽高尺寸
    this.saveScreenshotSize = { width: 0, height: 0 };
    // 缩放比例
    this.scale = 1;
    // 从JSON中解析后得到的柜子门板样式，图片URL和坐标尺寸
    this.frameData = null;
    // 从JSON中解析后得到的顶板样式，图片URL和坐标尺寸
    this.topPanelData = null;
    // 从JSON中解析后得到的支腿样式，坐标尺寸
    this.legData = null;
    // MCC初始化时的支腿样式，对应legStyles，用于refresh时恢复原先支腿样式
    this.initLegData = null;
    // 支腿素材图片URL，用户切换其他支腿会更新该URL
    this.legImgUrl = null;
    // 支腿在移动端页面上缩放后的显示高度
    this.legHeigth = 0;
    // 支腿商品真实高度
    this.originLegHeigth = 0;
    // 支腿总对数
    this.legLogarithm = 0;
    // 相连底柜的支腿安装类型，如果为1，代表每个底柜4个支腿；为0有4+2(n-1)个支腿n是底柜数量，每个柜子连接处共用一个支腿
    // 记录的原始值，每次调整支腿只会更改this.templateJson.basic.legSpecialFlag的值
    this.originLegSpecialFlag = '0';
    // 支撑腿总价, 用户调整去除支腿后该值可能仍不为0，因为这值只是记录mcc如果有支腿时那支撑腿总价是多少
    this.supportLegsPrice = 0;
    // 抽屉轨道数量（对）
    this.drawerQuantity = 0;
    // 电视机样式，图片URL和坐标尺寸，可放最大尺寸电视
    this.tvData = null;
    // 是否有电视机，用于控制渲染区域是否显示或隐藏电视机
    this.hasTV = false;
    // 用户调整的电视机尺寸css样式
    this.selectTvSize = null;
    // 地面图片URL
    this.floorImg = null;
    // 墙面图片URL
    this.wallImg = null;
    // 所有放在地面上的柜子ID
    this.bottomFrames = [];
    // 所有柜子的ComponentId，切换全部框体时传给后端用于获取相同结构不同颜色的框体素材图
    this.allFramesComponentId = [];
    // 所有门板的ComponentId，切换全部门板时传给后端用于获取相同尺寸不同颜色的门板素材图
    this.allDoorsComponentId = [];
    /**
     * @property {object} mccMobileSize mcc在手机渲染区域的实际整体尺寸
    */
    this.mccMobileSize = { width: 0, height: 0 };
    /**
     * 标尺样式（长宽位置）, horizontal是宽标尺, vertical是高标尺
     * @property {object} horizontal left表示宽标尺x轴位置，宽标尺应该在x轴左边第一个柜子那
     * @property {object} vertical bottom表示高标尺y轴位置，高标尺应该在y轴底部第一个柜子那
     */
    this.markingStyles = {
      horizontal: { width: 0, left: 0 },
      vertical: { height: 0, bottom: 0 },
    };
    // mcc template json 版本号
    this.mccVersion = '1.0';
  }

  setWarpperStylesPaddingLeft(left) {
    if (left && this.saveScreenshotSize.height > this.saveScreenshotSize.width) {
      const tempWarpperStyles = {
        ...this.warpperStyles,
        paddingLeft: left,
      };
      this.warpperStyles = tempWarpperStyles;
    }
  }

  /**
   * @description: 设置mcc整体外层地面偏移量
   * @param {*}
   * @return {*}
   * @author: Zhanyu Shen
   */
  setWarpperHeight(topSpaceScale) {
    const tempWarpperStyles = {
      ...this.warpperStyles,
      height: `calc(100% + ${topSpaceScale}px)`,
    };
    this.warpperStyles = tempWarpperStyles;
  }

  /**
   * @description: 初始化MCC
   * @param {*} templateJson 从后端获取的MCC结构JSON，该JSON里定义了柜子，门板，支腿，顶板的坐标和素材
   * @param {*} mobileMetopeWidth // 移动端渲染区域宽
   * @param {*} mobileMetopeHeight // 移动端渲染区域高
   * @return {*}
   * @author: Zhanyu Shen
   */
  initMcc(templateJson, mobileMetopeWidth, mobileMetopeHeight) {
    try {
      if (templateJson) {
        if ((mobileMetopeWidth && mobileMetopeHeight) || (this.mobileWidth && this.mobileHeight)) {
          // 设置核心参数
          this.templateJson = cloneDeep(templateJson);
          if (mobileMetopeWidth) { this.mobileWidth = mobileMetopeWidth; }
          if (mobileMetopeHeight) { this.mobileHeight = mobileMetopeHeight; }
          // 计算支撑腿总价
          const tsupportLegsPrice = templateJson.basic.supportLegsPrice || 0; // 支撑腿单价
          const supportLegsQuantity = templateJson.basic.supportLegsQuantity || 0; // 支撑腿数量
          this.supportLegsPrice = Number(tsupportLegsPrice) * Number(supportLegsQuantity);
          this.drawerQuantity = templateJson.basic.drawerQuantity || 0;
        } else {
          throw Error('MobileMetopeWidth and MobileMetopeHeight is NULL!');
        }
      } else {
        throw Error('TemplateJson is NULL!');
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  /**
   * @description: 获取MCC中的墙面地面，如果找不到则使用前端定义的默认值
   * @param {*}
   * @return {*}
   * @author: Zhanyu Shen
   */
  getWallAndFloor() {
    // set default wall
    const mccWall = this.templateJson?.basic?.wallColor;
    let defaultWall = wallProductDrawing.find((item) => item.id === mccWall);
    if (!defaultWall) {
      defaultWall = wallProductDrawing.find((item) => !!item.default);
    }
    this.wallImg = defaultWall.img;

    // set default floor
    const mccFloor = this.templateJson?.basic?.floorColor;
    let defaultFloor = floorProductDrawing.find((item) => item.id === mccFloor);
    if (!defaultFloor) {
      defaultFloor = floorProductDrawing.find((item) => !!item.default);
    }
    this.floorImg = defaultFloor.img;
  }

  /**
   * @description: 获取MCC尺寸，用于设置标尺
   * @param {*}
   * @return {*}
   * @author: Zhanyu Shen
   */
  getMccSize() {
    // size 去掉顶柜留白和电视机
    const editorWidth = Number(this.templateJson?.basic?.size?.width);
    const editorHeight = Number(this.templateJson?.basic?.size?.height);
    const editorDeep = Number(this.templateJson?.basic?.size?.deep);
    // saveScreenshotSize 含有顶柜留白和电视机
    const editorScreenshotWidth = Number(this.templateJson?.basic?.saveScreenshotSize?.width);
    const editorScreenshotHeight = Number(this.templateJson?.basic?.saveScreenshotSize?.height);
    // mcc柜子整体宽高深，对应标尺显示数字
    this.size = { width: editorWidth, height: editorHeight, deep: editorDeep };
    this.saveScreenshotSize = { width: editorScreenshotWidth, height: editorScreenshotHeight };
  }

  /**
   * @description: 计算缩放比例，因为editor桌面端上MCC尺寸不同于手机页面，需要对原尺寸进行缩放
   * 手机渲染区域宽比editor桌面宽大，不需要缩小，保持原尺寸
   * 手机渲染区域宽比editor桌面宽小，缩放值为 手机/桌面
   * X轴和Y轴都计算下缩放比例，取最小值
   * 宽乘0.75 高减30像素，是为了给标尺腾出显示空间
   * @param {*}
   * @return {*}
   * @author: Zhanyu Shen
   */
  computeScale() {
    if (!this.mobileWidth || !this.mobileHeight) return;
    // 针对120 180宽点mcc，使其整体再缩小点
    let baseScale = 1;
    if (this.saveScreenshotSize.height < this.saveScreenshotSize.width) {
      if (this.saveScreenshotSize.width < 80) {
        baseScale = 0.4;
        this.setWarpperStylesPaddingLeft('40px');
      } else if (this.saveScreenshotSize.width < 140) {
        baseScale = 0.85;
        this.setWarpperStylesPaddingLeft('20px');
      } else if (this.saveScreenshotSize.width < 200) {
        baseScale = 0.85;
        this.setWarpperStylesPaddingLeft('15px');
      }
    } else if (this.saveScreenshotSize.width === 60 && this.saveScreenshotSize.height < 100) {
      baseScale = 0.45;
      this.setWarpperStylesPaddingLeft('25px');
    } else if (this.saveScreenshotSize.width < 200) {
      baseScale = 0.85;
      this.setWarpperStylesPaddingLeft(this.saveScreenshotSize.width < 140 ? '20px' : '15px');
    }

    const mobileMetopeWidthContent = this.mobileWidth - MOVELEFTPIXEL;
    const saveScreenshotWidth = this.saveScreenshotSize.width < 200 ? 200 : this.saveScreenshotSize.width;

    // let scaleX = (mobileMetopeWidthContent) > this.saveScreenshotSize.width
    //   ? 1 : ((mobileMetopeWidthContent) / this.saveScreenshotSize.width);
    // let scaleX = (mobileMetopeWidthContent * 0.75) / this.saveScreenshotSize.width;
    let scaleX = (mobileMetopeWidthContent * 0.75) / saveScreenshotWidth;
    // const scaleY = (this.mobileHeight - 30) > this.saveScreenshotSize.height
    //   ? 1 : ((this.mobileHeight - 30) / this.saveScreenshotSize.height);
    const scaleY = (this.mobileHeight - 30) / this.saveScreenshotSize.height;

    console.log(scaleX, scaleY, 'diffscale');
    scaleX = scaleX > scaleY ? scaleY : scaleX;
    scaleX *= baseScale;
    this.scale = scaleX;
    console.log(this.scale, 'scale');
  }

  getMetopeHeightPercent() {
    if (this.saveScreenshotSize.width >= 200 && this.saveScreenshotSize.width <= 600) {
      // 最大值最小值求斜率 宽度 212是 墙高 88%    680时 墙 82%
      const k = (82 - 88) / (680 - 212);
      const b = 82 - k * 680;
      return k * this.saveScreenshotSize.width + b;
    }
    // 特殊情况处理，对标上面basescale的处理方法
    if (this.saveScreenshotSize.height < this.saveScreenshotSize.width) {
      if (this.saveScreenshotSize.width < 80 || this.saveScreenshotSize.width < 140
         || this.saveScreenshotSize.width < 200) {
        return 88;
      }
    } else if (this.saveScreenshotSize.width === 60 && this.saveScreenshotSize.height < 100) {
      return 88;
    } else if (this.saveScreenshotSize.width < 200) {
      return 88;
    }
    return 85;
  }

  /**
   * @description: 获取电视尺寸坐标
   * @param {*}
   * @return {*}
   * @author: Zhanyu Shen
   */
  renderTV() {
    if (this.templateJson.propping && this.templateJson.propping.length > 0) {
      const tvPropping = this.templateJson.propping[0] || null;
      if (tvPropping) {
        const tvleft = (this.saveScreenshotSize.width * tvPropping.mobileProportion.x) * this.scale;
        const tvtop = (this.saveScreenshotSize.height * tvPropping.mobileProportion.y) * this.scale;
        const tvWidth = tvPropping.width * this.scale;
        const tvHeight = tvPropping.height * this.scale;
        const tempTV = {
          id: tvPropping.code,
          tvStyle: {
            left: `${tvleft}px`, top: `${tvtop}px`, width: `${tvWidth}px`, height: `${tvHeight}px`,
          },
          imageUrl: tvPropping.imageUrl,
        };
        this.tvData = tempTV; // MCC中可以放置最大尺寸TV的渲染样式

        let contrast = 1; // 电视机缩放比例
        let hasTvFlag = true;
        if (this.templateJson.basic.userTvCode) {
          // 有用户选择的电视尺寸
          const maxTv = TVSize.find((item) => item.id === tvPropping.code); // 获取MCC中可以放置电视机的最大尺寸
          const defaultTv = TVSize.find((item) => item.id === this.templateJson.basic.userTvCode); // 获取用户电视机尺寸
          if (defaultTv && maxTv && defaultTv.scale > 0) {
            contrast = maxTv.scale === 0 ? 0 : maxTv.scale / defaultTv.scale; // 计算电视机缩放比例
          } else {
            // 该MCC不能放电视机时，contrast为0
            contrast = 0;
          }
        }
        if (contrast === 1) {
          // 用户没有调整电视机，用户的电视机尺寸保持不变
          this.selectTvSize = { width: `${tvWidth}px`, height: `${tvHeight}px` };
        } else if (contrast !== 0) {
          // 用户调整了电视机，用户的电视机尺寸根据最大尺寸进行缩放
          const scaleTvWidth = tvWidth / contrast;
          const scaleTvHeight = tvHeight / contrast;
          const changeTV = {
            width: `${scaleTvWidth}px`,
            height: `${scaleTvHeight}px`,
          };
          this.selectTvSize = changeTV;
        } else {
          this.selectTvSize = { width: `${tvWidth}px`, height: `${tvHeight}px` };
          hasTvFlag = false;
        }
        this.hasTV = hasTvFlag;
      }
    }
  }
}
