/*
 * @description: Fixed data list
 * @Author: Zhanyu Shen
 * @Date: 2021-08-18 09:57:36
 * @LastEditors: Zhanyu Shen
 * @LastEditTime: 2022-04-22 10:19:59
 */
import whiteFrame from '@/static/frame/white.png';
import darkBrownFrame from '@/static/frame/darkbrown.png';
import burlyWoodFrame from '@/static/frame/burlywood.png';
import wall01 from '@/static/wall/wall_1.png';
import wall02 from '@/static/wall/wall_2.png';
import wall03 from '@/static/wall/wall_3.png';
import wall04 from '@/static/wall/wall_4.png';
import wall05 from '@/static/wall/wall_5.png';
import wall01s from '@/static/wall/wall_1_small.png';
import wall02s from '@/static/wall/wall_2_small.png';
import wall03s from '@/static/wall/wall_3_small.png';
import wall04s from '@/static/wall/wall_4_small.png';
import wall05s from '@/static/wall/wall_5_small.png';
import floor01 from '@/static/floor/floor_1.png';
import floor02 from '@/static/floor/floor_2.png';
import floor03 from '@/static/floor/floor_3.png';
import floor04 from '@/static/floor/floor_4.png';
import floor05 from '@/static/floor/floor_5.png';
import floor01s from '@/static/floor/floor_1_small.png';
import floor02s from '@/static/floor/floor_2_small.png';
import floor03s from '@/static/floor/floor_3_small.png';
import floor04s from '@/static/floor/floor_4_small.png';
import floor05s from '@/static/floor/floor_5_small.png';
import MuteTrack from '@/static/drawer/MuteTrack.gif';
import PropulsionTrack from '@/static/drawer/PropulsionTrack.gif';

/*
 * 框体颜色的产品图
 */
const frameProductDrawing = [
  { id: '23166', img: burlyWoodFrame, colour: '仿白色橡木纹' },
  { id: '10156', img: whiteFrame, colour: '白色' },
  { id: '10139', img: darkBrownFrame, colour: '黑褐色' },
];

/*
 * 抽屉轨道的产品图
 */
const drawerProductDrawing = {
  31186: PropulsionTrack, // 推进式抽屉轨道
  31185: MuteTrack, // 静音抽屉轨道
};

/*
 * 墙面颜色的产品图
 */
const wallProductDrawing = [
  {
    id: 'wall01', img: wall01, iconStyle: { backgroundImage: `url(${wall01s})` }, default: true,
  },
  { id: 'wall02', img: wall02, iconStyle: { backgroundImage: `url(${wall02s})` } },
  { id: 'wall03', img: wall03, iconStyle: { backgroundImage: `url(${wall03s})` } },
  { id: 'wall04', img: wall04, iconStyle: { backgroundImage: `url(${wall04s})` } },
  { id: 'wall05', img: wall05, iconStyle: { backgroundImage: `url(${wall05s})` } },
];

/*
 * 地面颜色的产品图
 */
const floorProductDrawing = [
  {
    id: 'floor01', img: floor01, iconStyle: { backgroundImage: `url(${floor01s})` }, default: true,
  },
  { id: 'floor02', img: floor02, iconStyle: { backgroundImage: `url(${floor02s})` } },
  { id: 'floor03', img: floor03, iconStyle: { backgroundImage: `url(${floor03s})` } },
  { id: 'floor04', img: floor04, iconStyle: { backgroundImage: `url(${floor04s})` } },
  { id: 'floor05', img: floor05, iconStyle: { backgroundImage: `url(${floor05s})` } },
];

/*
 * 电视机尺寸
 */
const TVSize = [
  {
    id: '0', scale: 0, size: 0, default: true,
  },
  { id: '551220', scale: 0.671, size: 55 },
  { id: '601320', scale: 0.742, size: 60 },
  { id: '651440', scale: 0.791, size: 65 },
  { id: '701560', scale: 0.854, size: 70 },
  { id: '751670', scale: 0.916, size: 75 },
  { id: '821830', scale: 1, size: 82 },
];

/*
 * mcc十种颜色
 */
const mccColorList = [
  'rgba(94, 54, 59, 1)',
  'rgba(46, 43, 40, 1)',
  'rgba(191, 203, 203, 1)',
  'rgba(102, 89, 82, 1)',
  'rgba(244, 243, 239, 1)',
  'rgba(205, 182, 152, 1)',
  'rgba(199, 187, 173)',
  'rgba(164, 184, 172, 1)',
  'rgba(212, 166, 134, 1)',
  'rgba(243, 245, 246, 1)',
];

const clickObjectName = {
  sizeable: 0,
  iType: 1,
  towType: 2,
  halfSurroundedType: 3,
  surroundedType: 4,
  specialType: 5,
  lowPriority: 6,
  highPriority: 7,
  noLimit: 8,
  notOnTheWall: 9,
  canOnTheWall: 10,
  properties: {
    0: { name: 'sizeable', value: '尺寸可调' },
    1: { name: 'iType', value: '一字型' },
    2: { name: 'towType', value: '二字型' },
    3: { name: 'halfSurroundedType', value: '半包围' },
    4: { name: 'surroundedType', value: '全包围' },
    5: { name: 'specialType', value: '特殊型' },
    6: { name: 'lowPriority', value: '低价优先' },
    7: { name: 'highPriority', value: '高价优先' },
    8: { name: 'noLimit', value: '不限' },
    9: { name: 'notOnTheWall', value: '非上墙' },
    10: { name: 'canOnTheWall', value: '可上墙' },
  },
};

const LocalStorageValue = {
  TRUE: 'true',
  ADJUST: 'adjust',
  COLLECT: 'collect',
};

const SessionStorageValue = {
  SMP: 'smp',
  APP: 'app',
  IKEACN: 'ikeacn',
  TRUE: 'true',
};

const TemplateType = {
  DESIGN: '3', // 调整后的mcc
  MCC: '1', // 正常的mcc
};

export {
  frameProductDrawing,
  drawerProductDrawing,
  wallProductDrawing,
  floorProductDrawing,
  TVSize,
  mccColorList,
  clickObjectName,
  LocalStorageValue,
  SessionStorageValue,
  TemplateType,
};
