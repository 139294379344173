/*
 * @description: Besta MCC行为事件类，包含调整MCC框体, 门板, 支腿, 抽屉, 顶板，以及渲染见面的其他操作
 * @Author: Zhanyu Shen
 * @Date: 2022-01-12 14:16:13
 * @LastEditors: Zhanyu Shen
 * @LastEditTime: 2022-03-08 17:34:02
 */
import { defineProperty } from '@/utils/index';
import { FIRSTANDSECONDOFFSET } from '@/utils/const';
import { cloneDeep } from 'lodash';
import BestaMcc2DRender from './BestaMcc2DRender';

/**
 * @version 1.0
 * @description: Besta MCC行为事件类，包含调整MCC框体, 门板, 支腿, 抽屉, 顶板，以及渲染见面的其他操作, 处理只在1.0版本中的业务
 * 目前有：添加去除支腿改变MCC样式（changeLegStyles）
 * @author: Zhanyu Shen
 */
export default class BestaMcc2DActionEvent extends BestaMcc2DRender {
  /**
  * @description: 初始化MCC
  * @param {*} templateJson 从后端获取的MCC结构JSON，该JSON里定义了柜子，门板，支腿，顶板的坐标和素材
  * @param {*} mobileMetopeWidth // 移动端渲染区域宽
  * @param {*} mobileMetopeHeight // 移动端渲染区域高
  * @param {*} accessories // 切换新宽度MCC后上一个MCC调整的配件信息，
  * 目前只套用框体和门板 { frame, woodDoor, glassDoor } see initState.js previousAccessories
  * @return {*}
  * @author: Zhanyu Shen
  */
  async initMcc(templateJson, mobileMetopeWidth, mobileMetopeHeight, accessories) {
    super.initMcc(templateJson, mobileMetopeWidth, mobileMetopeHeight);
    this.selectDoors = null;
    this.changeDoorData = null;
    this.showFunction = false;

    let initPrice = 0;
    let initTemplateJson = templateJson;
    if (accessories && accessories.templateId) {
      initPrice = accessories.price || 0;
      if (accessories.frame) {
        // 套用上一个MCC调整的框体
        const { newPrice, newTemplateJson } = await this.changeFrame(accessories.frame, initPrice);
        initPrice = newPrice;
        initTemplateJson = newTemplateJson;
      }
      if (accessories.woodDoor) {
        // 套用上一个MCC调整的木质门板
        const { newPrice, newTemplateJson } = await this.changeDoor(
          accessories.woodDoor, initPrice, accessories.templateId,
        );
        initPrice = newPrice;
        initTemplateJson = newTemplateJson;
      }
      if (accessories.glassDoor) {
        // 套用上一个MCC调整的玻璃门板
        const { newPrice, newTemplateJson } = await this.changeDoor(
          accessories.glassDoor, initPrice, accessories.templateId,
        );
        initPrice = newPrice;
        initTemplateJson = newTemplateJson;
      }
    }
    return { newPrice: initPrice, newTemplateJson: initTemplateJson };
  }

  /**
   * @description: 用户调整支腿，更新支腿样式和template json
   * @param {Object} leg 调整的支腿
   * @param {Number} legUnitPrice 调整前的旧支腿单价
   * @param {Number} price 调整前的MCC总预估价格
   * @return {*}
   * @author: Zhanyu Shen
   */
  changeLeg(leg, legUnitPrice, price) {
    let newPrice = price;
    if (leg) {
      // 更新支腿样式
      newPrice = this.changeLegStyles(leg, legUnitPrice, price);
    }
    return super.changeLeg(leg, newPrice);
  }

  /**
   * @description: 用户调整支腿需要变化支腿素材图片，更新总预估价格，注意特殊支腿和普通支腿相互切换
   * @param {Object} newLeg 调整的支腿
   * @param {Number} legUnitPrice 调整前的旧支腿单价
   * @param {Number} price 调整前的MCC总预估价格
   * @return {*}
   * @author: Zhanyu Shen
   */
  changeLegStyles(newLeg, legUnitPrice, price) {
    let changeTop = 0;
    let newPrice = price;
    if (newLeg.isDisable) {
      // 去掉支腿
      changeTop = this.legHeigth;
      if (legUnitPrice && !Number.isNaN(legUnitPrice)) {
        // 初始有支腿，去掉支腿时减去相应价格
        newPrice = price - legUnitPrice * this.legLogarithm - this.supportLegsPrice;
      }
      this.legData = null;
    } else {
      changeTop = this.legData ? 0 : -Number(this.legHeigth);
      // 更新支腿素材图片，如果更换的支腿类型同原先支腿，style不会变，只变化图片
      // this.legImgUrl = newLeg.materialUrl;
      const random = Math.random();
      this.legImgUrl = `${newLeg.materialUrl}?random=${random}`;
      let newLegLogarithm = this.legLogarithm;
      // 如果调整的支腿类型跟原始类型不一样
      if (this.originLegSpecialFlag !== newLeg.specialFlag) {
        // 当前支腿类型不同与上一个所选支腿
        const previousLegSpecialFlag = this.templateJson.basic.legSpecialFlag;
        if (!(previousLegSpecialFlag && previousLegSpecialFlag === newLeg.specialFlag) || !this.legData) {
          const tempLegStyles = [];
          let totalPairs = 0;
          this.initLegData.forEach((group) => {
            const lastLegIndex = group.length - 1;
            const eachGroupLegs = [];
            group.forEach((leg, index) => {
              switch (this.originLegSpecialFlag) {
                case '0':
                  // 原始支腿可共用，改为不可共用
                  if (index === 0) {
                    // 第一个支腿样式不变
                    eachGroupLegs.push(leg);
                  } else if (index === lastLegIndex) {
                    // 最后一个支腿需要翻转
                    eachGroupLegs.push({
                      id: leg.id,
                      legStyle: {
                        height: leg.legStyle.height,
                        left: leg.legStyle.left,
                        transform: 'translateX(-100%) rotateY(180deg)',
                      },
                      originalLeft: leg.originalLeft,
                      key: random,
                    });
                  } else {
                    // 底柜连接处需要两个支腿
                    eachGroupLegs.push({
                      id: leg.id,
                      legStyle: {
                        height: leg.legStyle.height,
                        left: `${leg.originalLeft - FIRSTANDSECONDOFFSET}px`, // 连接处第一个支腿需要左移
                        transform: 'translateX(-100%) rotateY(180deg)',
                      },
                      originalLeft: leg.originalLeft,
                      key: random,
                    });
                    eachGroupLegs.push({
                      id: `${leg.id}-copy`,
                      legStyle: {
                        height: leg.legStyle.height,
                        left: `${leg.originalLeft + FIRSTANDSECONDOFFSET}px`, // 连接处第二个支腿需要右移
                      },
                      originalLeft: leg.originalLeft,
                      key: random,
                    });
                  }
                  break;
                case '1':
                  // 原始 NANNARP 纳普 支腿，不可共用，改为可共用
                  if (index === 0) {
                    // 第一个支腿样式不变
                    eachGroupLegs.push(leg);
                  } else if (index === lastLegIndex) {
                    // 最后一个支腿不需要翻转
                    eachGroupLegs.push({
                      id: leg.id,
                      legStyle: {
                        height: leg.legStyle.height,
                        left: leg.legStyle.left,
                        transform: 'translateX(-100%)',
                      },
                      originalLeft: leg.originalLeft,
                      key: random,
                    });
                  } else if (index % 2 === 0) {
                    // 底柜连接处只需要一个支腿
                    eachGroupLegs.push({
                      id: leg.id,
                      legStyle: {
                        height: leg.legStyle.height,
                        left: `${leg.originalLeft}px`, // 共用支腿不需要偏移量
                        transform: 'translateX(-50%)',
                      },
                      originalLeft: leg.originalLeft,
                      key: random,
                    });
                  }
                  break;
                default:
                  break;
              }
            });
            tempLegStyles.push(eachGroupLegs);
            totalPairs += eachGroupLegs.length;
          });
          this.legData = tempLegStyles;
          newLegLogarithm = totalPairs;
        }
      } else {
        let totalPairs = 0;
        const tempLegStyles = [];
        this.initLegData.forEach((group) => {
          const eachGroupLegs = [];
          group.forEach((leg) => {
            if (leg) {
              totalPairs += 1;
              eachGroupLegs.push({ ...leg, key: random });
            }
          });
          tempLegStyles.push(eachGroupLegs);
        });
        this.legData = tempLegStyles;
        newLegLogarithm = totalPairs;
      }

      let legpriceDiff = 0;
      const newLegUnitPrice = Number(newLeg.price); // 新支腿单价
      if (legUnitPrice && !Number.isNaN(legUnitPrice)) {
        legpriceDiff = newLegUnitPrice * newLegLogarithm - legUnitPrice * this.legLogarithm;
      } else {
        legpriceDiff = newLegUnitPrice * newLegLogarithm;
      }
      newPrice = price + legpriceDiff + (changeTop !== 0 ? this.supportLegsPrice : 0);
      this.legLogarithm = newLegLogarithm;
    }
    if (changeTop !== 0) {
      // change frame top
      const newFrameData = cloneDeep(this.frameData);
      newFrameData.forEach((frame) => {
        if (this.bottomFrames.includes(frame.id)) {
          defineProperty(frame.frameStyle, 'top', `${Number(frame.frameStyle.top.slice(0, -2)) + changeTop}px`);
        }
      });
      this.frameData = newFrameData;

      // change topPanel top
      const newTopPanelData = cloneDeep(this.topPanelData);
      newTopPanelData.forEach((topPanel) => {
        if (topPanel.isOnBottomFrames) {
          defineProperty(topPanel.panelStyle, 'top', `${Number(topPanel.panelStyle.top.slice(0, -2)) + changeTop}px`);
        }
      });
      this.topPanelData = newTopPanelData;

      // change tv top
      if (this.tvData) {
        const newTvData = cloneDeep(this.tvData);
        newTvData.tvStyle.top = `${Number(newTvData.tvStyle.top.slice(0, -2)) + changeTop}px`;
        this.tvData = newTvData;
      }

      // change marking height
      // 有上墙的柜子，size不变
      // 没有上墙的柜子（都是底柜），有TV改size，无TV改size和saveScreenshotSize
      // 现在size修改点：电视高度不计入size中（标尺高）
      if (this.bottomFrames.length === this.frameData.length) {
        const oldHeight = this.markingStyles.vertical.height;
        const tempMarkingStyles = {
          horizontal: { ...this.markingStyles.horizontal },
          vertical: {
            height: oldHeight - changeTop, bottom: this.markingStyles.vertical.bottom,
          },
        };
        this.markingStyles = tempMarkingStyles;
        this.size.height += (changeTop > 0 ? -this.originLegHeigth : this.originLegHeigth);
      }
    }
    return newPrice;
  }
}
