/*
 * @FilePath: \besta-online-web\src\redux\reducer.js
 * @Author: Zhanyu Shen
 * @Date: 2021-07-06 11:06:25
 * @LastEditors: Yehao Gao
 * @LastEditTime: 2022-02-08 14:14:54
 */
import { useReducer } from 'react';
import produce from 'immer';
import initState from './initState';
import mccListAction from './reducers/mccList';
import sideBarAction from './reducers/sideBar';
import adjustmentAction from './reducers/adjustment';
import detailsAction from './reducers/details';


const obj = {
  ...mccListAction,
  ...sideBarAction,
  ...adjustmentAction,
  ...detailsAction,
};

// const initState = { count: 1, viewModel: '1' };
function Reducer() {
  const [storeState, dispatch] = useReducer(
    produce((draft, action) => {
      // draft is state
      // console.log({ ...draft.renderData }, 'produce state');
      // const deepState = JSON.parse(JSON.stringify(state));
      const { type, payload } = action;
      const fn = obj[type];
      if (fn) {
        // return fn(deepState, payload);
        return fn(draft, payload);
      }
      return { ...draft };
    }), initState,
  );
  return { storeState, dispatch };
}

export default Reducer;

