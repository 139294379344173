/*
 * @description: Besta MCC渲染类，包含MCC框体, 门板, 支腿, 抽屉, 顶板数据和样式
 * @Author: Zhanyu Shen
 * @Date: 2022-01-12 14:16:13
 * @LastEditors: Zhanyu Shen
 * @LastEditTime: 2022-03-18 20:43:36
 */
import {
  GROUNDDEVIATION, TOPPANELHEIGHT, COMMONTOPPANELTYPECODE, FIRSTANDSECONDOFFSET,
} from '@/utils/const';
import BestaMccActionEvent from '../basicRender/BestaMccActionEvent';

export default class BestaMcc2DRender extends BestaMccActionEvent {
  /**
   * @version 1.0
   * @description: Besta MCC渲染类，包含MCC框体, 门板, 支腿, 抽屉, 顶板数据和样式
   * @return {*}
   * @author: Zhanyu Shen
   */
  constructor() {
    super();
    // mcc整体外层样式, height地面偏移量，模拟地面上的柜子有透视效果 paddingLeft竖长型mcc往右移动一点，使其在屏幕中看上去居中点
    this.warpperStyles = { paddingLeft: 0, height: `calc(100% + ${GROUNDDEVIATION}px)` };
  }

  /**
   * @description: 初始化MCC
   * @param {*} templateJson 从后端获取的MCC结构JSON，该JSON里定义了柜子，门板，支腿，顶板的坐标和素材
   * @param {*} mobileMetopeWidth // 移动端渲染区域宽
   * @param {*} mobileMetopeHeight // 移动端渲染区域高
   * @return {*}
   * @author: Zhanyu Shen
   */
  initMcc(templateJson, mobileMetopeWidth, mobileMetopeHeight) {
    try {
      super.initMcc(templateJson, mobileMetopeWidth, mobileMetopeHeight);
      // 开始渲染整个MCC结构
      this.getWallAndFloor();
      this.getMccSize();
      this.computeScale();
      this.setWarpperHeight(GROUNDDEVIATION * this.scale);
      this.getMarkingStyle();
      this.getAllStyle();
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  /**
   * @description: 计算标尺在手机页面上实际尺寸
   * @param {*}
   * @return {*}
   * @author: Zhanyu Shen
   */
  getMarkingStyle() {
    // 计算mcc在手机页面上整体实际尺寸
    const mccWidth = Math.max(this.size.width, this.saveScreenshotSize.width) * this.scale;
    const mccHeight = Math.max(this.size.height, this.saveScreenshotSize.height) * this.scale;
    this.mccMobileSize = { width: mccWidth, height: mccHeight };
    // 计算宽高标尺
    // groundHeight为最底部的柜体离地高，有放地面上的柜子为0，都是挂墙的柜子则该值不为0
    const groundHeight = this.templateJson?.basic?.groundHeight || 0;
    const tempMarkingStyles = {
      horizontal: { width: this.size.width * this.scale, left: this.markingStyles.horizontal.left },
      vertical: {
        height: this.size.height * this.scale, bottom: groundHeight * this.scale,
      },
    };
    this.markingStyles = tempMarkingStyles;
  }

  /**
   * @description: 解析整个template json，获取所有配件尺寸坐标
   * @param {*}
   * @return {*}
   * @author: Zhanyu Shen
   */
  getAllStyle() {
    if (!this.templateJson) return;
    // set legs
    this.renderLegs();
    // set topPanel
    this.renderTopPanels();
    // set TV
    this.renderTV();
    // set frames
    this.renderFrames();
  }

  /**
   * @description: 获取支腿尺寸坐标
   * @param {*}
   * @return {*}
   * @author: Zhanyu Shen
   */
  renderLegs() {
    const tempLegs = [];
    const allBottomFramesId = [];
    if (this.templateJson.basic.legs && this.templateJson.basic.legs.length > 0) {
      const firstLeg = this.templateJson.basic.legs[0].componentBasic[0];
      // 初始化时支腿图片
      const legimg = firstLeg?.imageUrl || null;
      this.legImgUrl = legimg;

      let legMobileHeight = firstLeg?.mobileHeight || 0;
      legMobileHeight = (this.saveScreenshotSize.height * legMobileHeight) * this.scale;
      this.originLegHeigth = firstLeg?.height; // 支腿原始高度
      this.legHeigth = legMobileHeight; // 手机页面上缩放后的支腿高度，去除支腿时需要移动底柜，更新坐标会用到这个高度
      // 遍历支腿数组，根据legSpecialFlag处理不同类型支腿
      let legIndex = 0; // 所有支腿索引，与id一起作为key
      const tempLegSpecialFlag = this.templateJson.basic.legSpecialFlag || '0';
      this.templateJson.basic.legs.forEach((legGroup) => {
        // 一个legGroup代表几个连在一起的柜体下的一组支腿
        allBottomFramesId.push(...legGroup.frames);
        const eachGroupLegs = [];
        if (legGroup.componentBasic && legGroup.componentBasic.length > 0) {
          const lastLegIndex = legGroup.componentBasic.length - 1; // 最后一个支腿索引
          legGroup.componentBasic.forEach((leg, index) => {
            // mobileX为单个支腿x坐标与整体mcc宽的比例
            let left = (this.saveScreenshotSize.width * leg.mobileX) * this.scale; // 计算单个支腿在手机上x坐标
            const originalLeft = left; // 记录支腿初始x坐标，用于特殊和普通支腿相互切换时计算新的x坐标
            const legStyle = { height: `${legMobileHeight}px` };
            switch (tempLegSpecialFlag) {
              case '0':
                // 支腿X轴坐标偏移量，第一个向右移动，最后一个向左移动
                if (index === 0) {
                  left += FIRSTANDSECONDOFFSET;
                } else if (index === lastLegIndex) {
                  left -= FIRSTANDSECONDOFFSET;
                  legStyle.transform = 'translateX(-100%)';
                } else {
                  // 中间支腿向右移动本身一半宽的位置，使其有共用支腿的效果
                  legStyle.transform = 'translateX(-50%)';
                }
                break;
              case '1':
                // 支腿X轴坐标偏移量，index偶数向右移动，奇数向左移动
                // NANNARP纳普支腿，该支腿不能共用
                if (index % 2 === 0) {
                  left += FIRSTANDSECONDOFFSET;
                } else {
                  left -= FIRSTANDSECONDOFFSET;
                  legStyle.transform = 'translateX(-100%) rotateY(180deg)';
                }
                break;
              default:
                break;
            }
            legStyle.left = `${left}px`;
            eachGroupLegs.push({ id: `${leg.uuid}-${legIndex}`, legStyle, originalLeft });
            legIndex += 1;
          });
        }
        tempLegs.push(eachGroupLegs);
      });

      this.bottomFrames = allBottomFramesId;
      // editor那没有选择初始支腿，imageUrl会为空，所以LegStyles也要为空
      this.legData = legimg ? tempLegs : null;
      this.initLegData = tempLegs;
      this.legLogarithm = legIndex; // 支腿总对数
      this.originLegSpecialFlag = tempLegSpecialFlag;
    }
  }

  /**
   * @description: 获取顶板尺寸坐标
   * @param {*}
   * @return {*}
   * @author: Zhanyu Shen
   */
  renderTopPanels() {
    const tempTopPanels = [];
    const allBottomFramesId = this.bottomFrames;
    if (this.templateJson.basic.topPanel && this.templateJson.basic.topPanel.length > 0) {
      this.templateJson.basic.topPanel.forEach((panelGroup) => {
        const panelGroupId = panelGroup.uuid || 0;
        // 判断顶板所在的柜子是否在地面上，添加或去除支腿时顶板也要移动坐标
        const isOnBottomFrames = panelGroup.frames
          ? panelGroup.frames.filter((frame) => allBottomFramesId.includes(frame)).length > 0 : false;
        if (panelGroup.componentBasic && panelGroup.componentBasic.length > 0) {
          panelGroup.componentBasic.forEach((panel, topPanelIndex) => {
            // 所在柜体高度不超过166cm才能安装顶板，不可安装时notInstall为true
            if (panel && !panel.notInstall) {
              const panelLeft = (this.saveScreenshotSize.width * panel.mobileProportion.x) * this.scale;
              const panelTop = (this.saveScreenshotSize.height * panel.mobileProportion.y) * this.scale;
              const panelWidth = (this.saveScreenshotSize.width * panel.mobileWidth) * this.scale;
              // 板子有厚度，向上移动厚度的px，不然选择门板时上边框会被遮盖
              const panelStyle = {
                left: `${panelLeft}px`,
                top: `${panelTop - (panel.height || TOPPANELHEIGHT) * this.scale}px`,
                width: `${panelWidth}px`,
              };
              tempTopPanels.push({
                id: `${panelGroupId}-${topPanelIndex}`,
                componentId: panel.componentId || null,
                width: panel.width || 120,
                typeCode: panel.typeCode || COMMONTOPPANELTYPECODE,
                panelStyle,
                imageUrl: panel.imageUrl || null,
                isOnBottomFrames,
              });
            }
          });
        }
      });
      this.topPanelData = tempTopPanels;
    }
  }

  /**
   * @description: 获取框体和门板尺寸坐标
   * @param {*}
   * @return {*}
   * @author: Zhanyu Shen
   */
  renderFrames() {
    const tempFrameDatas = [];
    const allLeft = [];
    const tempFramesComponentId = [];
    const tempDoorsComponentId = [];
    this.templateJson.frames.forEach((frame) => {
      let tempFrame = {};
      // 自适应，editro无边距
      const widthNum = (this.saveScreenshotSize.width * frame.componentBasic.mobileProportion.w) * this.scale;
      const leftNum = (this.saveScreenshotSize.width * frame.componentBasic.mobileProportion.x) * this.scale;
      const topNum = (this.saveScreenshotSize.height * frame.componentBasic.mobileProportion.y) * this.scale;
      // 设置框体z轴值，因为上面的柜子可以叠放在下面柜子上面，所以越下面的柜子zIndex越小
      const zIndex = 10000 - Math.round(topNum);
      const componentId = frame.componentBasic.componentId || '';
      tempFramesComponentId.push(componentId);
      const frameID = frame.componentBasic.uuid || '';
      tempFrame = {
        id: frameID,
        componentId,
        frameUrl: frame.componentBasic.imageUrl || '',
        units: [],
      };
      if (frame.units.length > 0) {
        frame.units.forEach((unit, unitIndex) => {
          const proportion = Math.round(Number(frame.componentBasic.width) / 60);
          const unitHeight = unit.height ? Number(unit.height) : null; // 高柜中单元离底部高度
          const unitWidth = widthNum / proportion;
          let unitLeft = unitIndex * unitWidth; // 每个单元X坐标，单元位置*单元宽
          let unitBottom = 0;
          if (unitHeight) {
            unitLeft = 0;
            unitBottom = unitHeight * this.scale;
          }
          const unitStyle = {
            id: `${frame.componentBasic.uuid}-${unitIndex}`,
            style: { width: `${unitWidth}px`, left: `${unitLeft}px`, bottom: `${unitBottom}px` },
            doors: [],
          };
          // set door
          if (unit.door && unit.door.length > 0) {
            unit.door.forEach((eachDoor, doorIndex) => {
              const doorUrl = eachDoor.componentBasic.imageUrl || null;
              if (doorUrl) {
                // 计算每个门板Y坐标，一个单元中可以有多个门板，如果是高柜门板离地高减去单元离地高（计算门板相对单元所在高度）
                const eachDoorHeight = unitHeight
                  ? (Number(eachDoor.height) - Number(unitHeight)) : Number(eachDoor.height);
                const doorBottom = Number.isNaN(eachDoorHeight) ? 0 : eachDoorHeight * this.scale;
                unitStyle.doors.push({
                  // 门板ID构成要素为: frameID-unitIndex-doorIndex，所以在单独的change操作中门板id是不会更改的
                  // change操作中框体id也是不变的，一直为componentBasic.uuid，该uuid为admin editor前端生成，非后端生成的
                  id: `${frameID}-${unitIndex}-${doorIndex}`,
                  componentId: eachDoor.componentBasic.componentId,
                  appearanceId: eachDoor.componentBasic.appearanceId,
                  style: { bottom: `${doorBottom}px` },
                  doorUrl,
                  doorBorder: null,
                  // drawerPanelFlag表示是否是抽屉面板，用于功能分区
                  drawerPanelFlag: eachDoor.componentBasic.drawerPanelFlag || false,
                });
                tempDoorsComponentId.push(eachDoor.componentBasic.componentId);
              }
            });
          }
          if (unitStyle.doors.length > 0) {
            tempFrame.units.push(unitStyle);
          }
        });
      }
      const width = `${widthNum}px`;
      const left = `${leftNum}px`;
      const top = `${topNum}px`;
      tempFrame.frameStyle = {
        width, left, top, zIndex,
      };
      tempFrameDatas.push(tempFrame);
      allLeft.push(leftNum);
    });
    this.allFramesComponentId = tempFramesComponentId;
    this.allDoorsComponentId = tempDoorsComponentId;
    const minLeft = Math.min.apply(null, allLeft);
    const tempMarkingStyles = {
      horizontal: { width: this.markingStyles.horizontal.width, left: minLeft },
      vertical: { ...this.markingStyles.vertical },
    };
    this.markingStyles = tempMarkingStyles;
    this.frameData = tempFrameDatas;
  }
}
