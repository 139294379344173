import SlsWebLogger from 'js-sls-logger';

export const GetDevice = function () {
  /* 版本信息获取 */
  // 各主流浏览器
  const getBrowser = function () {
    const u = navigator.userAgent;
    const bws = [{
      name: 'sgssapp',
      it: /sogousearch/i.test(u),
    }, {
      name: 'wechat',
      it: /MicroMessenger/i.test(u),
    }, {
      name: 'weibo',
      it: !!u.match(/Weibo/i),
    }, {
      name: 'uc',
      it: !!u.match(/UCBrowser/i) || u.indexOf(' UBrowser') > -1,
    }, {
      name: 'sogou',
      it: u.indexOf('MetaSr') > -1 || u.indexOf('Sogou') > -1,
    }, {
      name: 'xiaomi',
      it: u.indexOf('MiuiBrowser') > -1,
    }, {
      name: 'baidu',
      it: u.indexOf('Baidu') > -1 || u.indexOf('BIDUBrowser') > -1,
    }, {
      name: '360',
      it: u.indexOf('360EE') > -1 || u.indexOf('360SE') > -1,
    }, {
      name: '2345',
      it: u.indexOf('2345Explorer') > -1,
    }, {
      name: 'edge',
      it: u.indexOf('Edge') > -1,
    }, {
      name: 'ie11',
      it: u.indexOf('Trident') > -1 && u.indexOf('rv:11.0') > -1,
    }, {
      name: 'ie',
      it: u.indexOf('compatible') > -1 && u.indexOf('MSIE') > -1,
    }, {
      name: 'firefox',
      it: u.indexOf('Firefox') > -1,
    }, {
      name: 'safari',
      it: u.indexOf('Safari') > -1 && u.indexOf('Chrome') === -1,
    }, {
      name: 'qqbrowser',
      it: u.indexOf('MQQBrowser') > -1 && u.indexOf(' QQ') === -1,
    }, {
      name: 'qq',
      it: u.indexOf('QQ') > -1,
    }, {
      name: 'chrome',
      it: u.indexOf('Chrome') > -1 || u.indexOf('CriOS') > -1,
    }, {
      name: 'opera',
      it: u.indexOf('Opera') > -1 || u.indexOf('OPR') > -1,
    }];
    for (let i = 0; i < bws.length; i += 1) {
      if (bws[i].it) {
        return bws[i].name;
      }
    }

    return 'other';
  };
  // 系统区分
  const getOS = function () {
    const u = navigator.userAgent;
    if (!!u.match(/compatible/i) || u.match(/Windows/i)) {
      return 'windows';
    } if (!!u.match(/Macintosh/i) || u.match(/MacIntel/i)) {
      return 'macOS';
    } if (!!u.match(/iphone/i) || u.match(/Ipad/i)) {
      return 'ios';
    } if (u.match(/android/i)) {
      return 'android';
    } if (u.match(/Ubuntu/i)) {
      return 'Ubuntu';
    }
    return 'other';
  };
  // 判断数组中是否包含某字符串
  // eslint-disable-next-line no-extend-native
  Array.prototype.contains = function (needle) {
    // eslint-disable-next-line no-restricted-syntax
    for (const i in this) {
      if (this[i].indexOf(needle) > 0) { return i; }
    }
    return -1;
  };

  const deviceType = navigator.userAgent; // 获取userAgent信息
  // document.write(device_type); //打印到页面
  // eslint-disable-next-line no-undef
  const md = new MobileDetect(deviceType); // 初始化mobile-detect
  let os = md.os(); // 获取系统
  console.log(os);
  let model = '';
  if (os === 'iOS') { // ios系统的处理
    os = +md.version('iPhone');
    console.log(os);
    model = md.mobile();
  } else if (os === 'AndroidOS') { // Android系统的处理
    os = md.os() + md.version('Android');
    const sss = deviceType.split(';');
    const i = sss.contains('Build/');
    if (i > -1) {
      model = sss[i].substring(0, sss[i].indexOf('Build/'));
    }
  } else {
    os = getOS();
    model = getBrowser();
  }
  // alert(os + "---" + model);//打印系统版本和手机型号
  // console.log(model + '||' + os, '打印系统版本和手机型号')
  return {
    os,
    dev: model,
  };
};
export function sendLog(message, sourceFn) {
  if (process.env.NODE_ENV === 'development') {
    return;
  }
  const opts = {
    host: 'cn-shanghai.log.aliyuncs.com',
    project: 'k8s-log-c47e29ac6bb324e5fabb1c4a22150a5c7',
    logstore: 'ikea-aidesign-besta-online-web-store',
    time: 3,
    count: 10,
  };
  const logger = new SlsWebLogger(opts);
  const device = JSON.stringify(GetDevice());
  const date = new Date().toUTCString();
  logger.send({
    type: 'error',
    sourceFn,
    msg: message,
    device,
    date,
    env: process.env.NODE_ENV,
  });
}
window.onerror = function (msg, url, lineNo, columnNo, error) {
  try {
    const message = [
      `Message: ${msg}`,
      `URL: ${url}`,
      `Line: ${lineNo}`,
      `Column: ${columnNo}`,
      `Error object: ${JSON.stringify(error)}`,
    ].join(' - ');
    sendLog(message, 'window.onerror');
  } catch (err) {
    sendLog(JSON.stringify(err), 'try-catch,window.onerror');
  }

  return false;
};

window.addEventListener('error', ({
  message, filename, lineno, colno, error,
}) => {
  const msg = [
    `Message: ${message}`,
    `URL: ${filename}`,
    `Line: ${lineno}`,
    `Column: ${colno}`,
    `Error object: ${JSON.stringify(error)}`,
  ].join(' - ');
  sendLog(msg, "window.addEventListener('error')");
});

