/*
 * @description: General tool class method
 * @Author: Zhanyu Shen
 * @Date: 2021-07-29 22:33:48
 * @LastEditors: Yongchao Wang
 * @LastEditTime: 2022-05-23 11:00:53
 */

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
import { LocalStorageValue } from './enum';
import { LOCALSTORAGE, SESSIONSTORAGE } from './const';

/**
 * Amount in thousands plus comma
 * @param {Number} amount
 * @param {Number} decimal
 * @returns {String}
 */
const formatAmount = (amount, decimal = 2) => {
  let defaultAmount = '';
  const setAmount = Number(`${amount}`);
  const formatDecimal = Number.isInteger(decimal) ? decimal : 2;
  // eslint-disable-next-line eqeqeq
  if (setAmount != 'null' && setAmount != '' && setAmount != 'undefined' && !Number.isNaN(setAmount)) {
    defaultAmount = setAmount.toFixed(formatDecimal).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    return defaultAmount;
  }
  if (setAmount === 0) {
    defaultAmount = '0.00';
    return defaultAmount;
  }
  return defaultAmount;
};

/**
 * Modify a property in a reference object, for eslint: no-param-reassign
 * @param {Object} obj
 * @param {String} key
 * @param {Object} val
 * @returns {String}
 */
function defineProperty(obj, key, val) {
  return Object.defineProperty(obj, key,
    {
      value: val,
      writable: true,
      configurable: true,
      enumerable: true,
    });
}

/**
 * @description: 厘米值判断逻辑函数
 * @param {*}
 * @return {*}
 * @author: yehao Gao
 */
const volume = (a, b, c) => {
  if (a && b && c) {
    return `${a}x${b}x${c} 厘米`;
  }
  if (a && b) {
    return `${a}x${b} 厘米`;
  }
  if (a && c) {
    return `${a}x${c} 厘米`;
  }
  if (b && c) {
    return `${b}x${c} 厘米`;
  }
  if (a) {
    return `${a}厘米`;
  }
  if (b) {
    return `${b}厘米`;
  }
  if (c) {
    return `${c}厘米`;
  }
  return '';
};

export function getCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const isIphonex = () => { // 判断是不是X类型手机
  // X XS, XS Max, XR，11， 11pro，11pro max，12mini，12， 12 pro，12 pro max
  const xSeriesConfig = [
    {
      devicePixelRatio: 3,
      width: 375,
      height: 812,
    },
    {
      devicePixelRatio: 3,
      width: 414,
      height: 896,
    },
    {
      devicePixelRatio: 2,
      width: 414,
      height: 896,
    },
    {
      devicePixelRatio: 3,
      width: 315,
      height: 812,
    },
    {
      devicePixelRatio: 3,
      width: 390,
      height: 844,
    },
    {
      devicePixelRatio: 3,
      width: 428,
      height: 926,
    },
    // 新增
    {
      devicePixelRatio: 3,
      width: 390,
      height: 844,
    },
    {
      devicePixelRatio: 3,
      width: 428,
      height: 926,
    },
    {
      devicePixelRatio: 3,
      width: 390,
      height: 844,
    },
    {
      devicePixelRatio: 3,
      width: 360,
      height: 780,
    },
    {
      devicePixelRatio: 3,
      width: 360,
      height: 780,
    },
  ];
  // h5
  if (typeof window !== 'undefined' && window) {
    const isIOS = /iphone/gi.test(window.navigator.userAgent);
    if (!isIOS) return false;
    const { devicePixelRatio, screen } = window;
    const { width, height } = screen;
    // eslint-disable-next-line
    return xSeriesConfig.some(item => item.devicePixelRatio === devicePixelRatio && item.width === width && item.height === height);
  }
  return false;
};

// 判断手机环境是否为ios
const isiOS = () => {
  const { userAgent } = navigator;
  const isiOSVal = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  if (isiOSVal) {
    return true;
  }
  return false;
};

// 判断是否是微信内置浏览器
const isWeixin = () => {
  if (typeof window.WeixinJSBridge === 'undefined' || typeof window.WeixinJSBridge.invoke === 'undefined') {
    return false;
  }
  return true;
};

// url参数转对象
function queryURL(url) {
  const params = url.indexOf('?') !== -1 ? url.split('?')[1].split('&') : url.split('&');
  const obj = {};
  params.forEach((item) => {
    if (item.indexOf('=') !== -1) {
      const param = item.split('=');
      // eslint-disable-next-line prefer-destructuring
      obj[param[0]] = param[1];
    }
  });
  return obj;
}

// 图片链接转base64
function imgToBase64(imgSrc, callback) {
  let dataURL;
  const img = new Image();
  // 允许资源跨域使用
  img.setAttribute('crossOrigin', 'anonymous');
  img.src = imgSrc;
  img.onload = () => {
    const imgWidth = img.width;
    const imgHeight = img.height;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = imgWidth;
    canvas.height = imgHeight;
    ctx.drawImage(img, 0, 0, imgWidth, imgHeight);
    dataURL = canvas.toDataURL('image/png');
    // eslint-disable-next-line no-unused-expressions
    callback && callback(dataURL);
    return `${dataURL}`;
  };
}
// 判断是否需要ios底部预留
const isAdapt = () => {
  const currentPlatform = sessionStorage.getItem('currentPlatform');
  const num = window.history.length;
  // const adaptation = (isIphonex() && currentPlatform !== 'app')
  //         && (currentPlatform === 'smp' || (currentPlatform !== 'smp' && isWeixin() && num === 1));
  const adaptation = isIphonex()
          && (currentPlatform === 'smp'
           || currentPlatform === 'app' || (currentPlatform !== 'smp' && isWeixin() && num === 1));
  if (adaptation) {
    return true;
  }
  return false;
};

// 删除cookies
const delCookie = (name) => {
  const exp = new Date();
  exp.setTime(exp.getTime() - 1);
  const cval = getCookie(name);
  if (cval != null) { document.cookie = `${name}=${cval};expires=${exp.toGMTString()}`; }
};

/**
 * 防抖
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
const debounce = (func, wait, immediate) => {
  let timeout; let params; let context; let timestamp; let
    result;
  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;
    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, params);
        if (!timeout) {
          context = null;
          params = null;
        }
      }
    }
  };
  return function (...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    params = args;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = null;
      // eslint-disable-next-line no-param-reassign
      args = null;
    }
    return result;
  };
};

// 加购埋点
const addToBagGio = ({
  page, designCode, color, size, mcc, payAmount, shape, pageSource,
}) => {
  window.gio('track', 'addToBag', {
    pageType_var: 'pip',
    page_var: page,
    designCode_var: designCode,
    color_var: color,
    size_var: size,
    mcc_var: mcc,
    payAmount_var: payAmount,
    shape_var: shape,
    pageSource_var: pageSource,
    platform_var: sessionStorage.getItem('gioPlatform'),
  });
};

// 联系客服埋点
const contactCSCGio = ({
  page, designCode, color, size, mcc, payAmount, shape, pageSource,
}) => {
  window.gio('track', 'contactCSC', {
    pageType_var: 'pip',
    page_var: page,
    designCode_var: designCode,
    color_var: color,
    size_var: size,
    mcc_var: mcc,
    payAmount_var: payAmount,
    shape_var: shape,
    pageSource_var: pageSource,
    platform_var: sessionStorage.getItem('gioPlatform'),
  });
};

// 加入备选埋点
const addToCollectGio = ({
  page, designCode, color, size, payAmount, shape, pageSource,
}) => {
  window.gio('track', 'addToCollect', {
    pageType_var: 'plp',
    page_var: page,
    designCode_var: designCode,
    color_var: color,
    size_var: size,
    payAmount_var: payAmount,
    shape_var: shape,
    pageSource_var: pageSource,
    platform_var: sessionStorage.getItem('gioPlatform'),
  });
};

// PLP筛选埋点
const plpFilterGio = ({
  page, position, clickObjectName, clickType,
}) => {
  window.gio('track', 'plpFilter', {
    pageType_var: 'plp',
    page_var: page,
    pageName_var: '贝达电视柜方案',
    modulePosition_var: '1',
    module_var: 'plp_filter_confirm',
    position_var: position,
    clickObjectName_var: clickObjectName,
    clickType_var: clickType,
    platform_var: sessionStorage.getItem('gioPlatform'),
  });
};

// 切换视图埋点
const changeBrowseModeGio = ({
  page, clickType,
}) => {
  window.gio('track', 'changeBrowseMode', {
    pageType_var: 'plp',
    page_var: page,
    pageName_var: '贝达电视柜方案',
    modulePosition_var: '0',
    module_var: 'Top Nav',
    position_var: 0,
    clickObjectName_var: '切换视图',
    clickType_var: clickType,
    platform_var: sessionStorage.getItem('gioPlatform'),
  });
};

// MCC点击进入埋点
const clickMCCGio = ({
  page, position, productInfo, productName, originAmount,
}) => {
  window.gio('track', 'clickMCC', {
    pageType_var: 'plp',
    page_var: page,
    pageName_var: '贝达电视柜方案',
    modulePosition_var: '2',
    module_var: 'Besta Product List',
    position_var: position,
    clickType_var: 'Product',
    productInfo_var: productInfo, // mccid
    productName_var: productName, // mcc名字
    originAmount_var: originAmount, // 商品金额
    platform_var: sessionStorage.getItem('gioPlatform'),
  });
};

// mcc详情页，点击调整按钮触发
const clickAdjustGio = ({
  page, productInfo, productName,
}) => {
  window.gio('track', 'clickAdjustment', {
    pageType_var: 'pip',
    page_var: page,
    pageName_var: '贝达电视柜详情页',
    modulePosition_var: '7',
    module_var: 'MCC Adjustment',
    position_var: 2,
    clickObjectName_var: '调整电视柜',
    productInfo_var: productInfo, // mccid
    productName_var: productName, // mcc名字
    platform_var: sessionStorage.getItem('gioPlatform'),
  });
};

// 跳过欢迎页埋点
const clickWelcomeSkip = ({
  sellingPoints,
}) => {
  window.gio('track', 'skipWelcome', {
    pageType_var: 'welcome',
    page_var: `${window.location.origin}/besta-online`,
    pageName_var: '贝达电视柜欢迎页',
    position_var: 0,
    clickObjectName_var: '跳过',
    sellingPoints_var: sellingPoints,
    platform_var: sessionStorage.getItem('gioPlatform'),
  });
};

// pip页点击去更换
const clickReplaceGio = ({
  page, productInfo, pageSource, position, clickObjectName,
}) => {
  window.gio('track', 'goToReplace', {
    pageType_var: 'pip',
    page_var: page,
    pageName_var: '贝达电视柜',
    productInfo_var: productInfo,
    pageSource_var: pageSource,
    position_var: position,
    platform_var: sessionStorage.getItem('gioPlatform'),
    clickObjectName_var: clickObjectName,
    modulePosition_var: '7',
    module_var: 'MCC Replace Parts',
  });
};

// 完成调整埋点
const finishAdjustmentGio = () => {
  window.gio('track', 'clickData', {
    eventType_var: 'click',
    platform_var: sessionStorage.getItem('gioPlatform'),
    pageType_var: 'layout_adjustment',
    pageId_var: window.location.href,
    pageName_var: '调整电视柜',
    module_var: 'TVBench_complete',
    modulePosition_var: '8',
    objectType_var: 'adjust_complete',
    objectName_var: '完成调整',
  });
};

// 详情页MCC是否缺货埋点
const pipIsOutOfStockGio = (isOutOfStock) => {
  window.gio('track', 'pageView', {
    eventType_var: 'page_view',
    platform_var: sessionStorage.getItem('gioPlatform'),
    pageType_var: 'besta_pip',
    pageId_var: window.location.href,
    pageName_var: '贝达电视柜',
    module_var: '-',
    modulePosition_var: '-',
    objectType_var: '-',
    objectName_var: '-',
    attributeName1_var: 'out_of_stuck_var',
    attributeValue1_var: isOutOfStock,
  });
};

// 调整页配件是否缺货埋点
const adjustmentIsOutOfStockGio = (isOutOfStock, tabName, tabChildName) => {
  let localTabName = tabName;
  if (tabName === 'width') {
    localTabName = 'width_choose';
  }
  if (tabName === 'frame') {
    localTabName = 'frame_color';
  }
  window.gio('track', 'clickData', {
    eventType_var: 'click',
    platform_var: sessionStorage.getItem('gioPlatform'),
    pageType_var: 'besta_adjustment',
    pageId_var: window.location.href,
    pageName_var: '调整电视柜',
    module_var: localTabName,
    modulePosition_var: '-',
    objectType_var: tabChildName,
    objectName_var: '-',
    attributeName1_var: 'out_of_stuck_var',
    attributeValue1_var: isOutOfStock,
  });
};

const abtestGio = (layout) => {
  window.gio('visitor.set', 'abtest_besta_design_layout', layout);
};
const smoothLandingAbTestGio = (layout) => {
  window.gio('visitor.set', 'abtest_besta_smooth_landing', layout);
};


// 卡片颜色
const cardColor = (mccColor) => {
  if (!mccColor || mccColor.indexOf('{') === -1) {
    return '';
  }
  const colorObj = JSON.parse(mccColor).doorColorName.length > 0
    ? JSON.parse(mccColor).doorColorName : JSON.parse(mccColor).frameColorName;
  let colorrArr = colorObj.filter((item) => item)
    .map((item) => (item.indexOf(' ') === -1 ? item : item.split(' ')[1]));
  colorrArr = Array.from(new Set(colorrArr));
  return colorrArr.length > 1 ? colorrArr.join('/') : `${colorrArr[0] || ''}`;
};

// 形状title
const shapeTitle = (type) => {
  switch (type) {
    case '1':
      return '一字型';
    case '2':
      return '二字型';
    case '3':
      return '半包围';
    case '4':
      return '全包围';
    case '5':
      return '特殊型';
    default:
      return '';
  }
};

const getStorage = (key, type = false) => { // 获取缓存中的值
  if (type) {
    return localStorage.getItem(key);
  }
  return sessionStorage.getItem(key);
};

const removeStorage = (key, type = false) => { // 移除缓存
  if (type) {
    localStorage.removeItem(key);
  }
  sessionStorage.removeItem(key);
};

const setStorage = (key, value, type = false) => { // set缓存
  if (type) {
    localStorage.setItem(key, value);
  }
  sessionStorage.setItem(key, value);
};

const loginCallback = (history, type = false) => { // 登录后跳转的页面跳转或者收藏行为的处理函数
  if (getStorage(SESSIONSTORAGE.userId) && getStorage(LOCALSTORAGE.onShow, true) === LocalStorageValue.TRUE) {
    if (getStorage(LOCALSTORAGE.replaceType, true) === LocalStorageValue.ADJUST
    && getStorage(LOCALSTORAGE.mccInfo, true)) {
      const mccInfo = JSON.parse(getStorage(LOCALSTORAGE.mccInfo, true));
      const { templateId, mccComboId } = mccInfo;
      if (type) {
        window.history.pushState({ templateId, templateType: '1', mccComboId }, '', '/pip');
        history.push({
          pathname: '/adjustment',
          search: `?id=${templateId}&templateType=1&mccComboId=${mccComboId}`,
        });
      } else {
        history.replace(
          {
            pathname: '/adjustment',
            // state: {
            //   id: templateId,
            //   templateType: '1',
            //   mccComboId,
            // },
            search: `?id=${templateId}&templateType=1&mccComboId=${mccComboId}`,
          },
        );
      }

      removeStorage(LOCALSTORAGE.mccInfo, true);
      removeStorage(LOCALSTORAGE.replaceType, true);
      removeStorage(LOCALSTORAGE.onShow, true);
    } else if (getStorage(LOCALSTORAGE.replaceType, true) === LocalStorageValue.COLLECT) {
      setStorage(LOCALSTORAGE.addAlternative, LocalStorageValue.TRUE, true);
      removeStorage(LOCALSTORAGE.replaceType, true);
      removeStorage(LOCALSTORAGE.onShow, true);
    }
  }
};

export {
  formatAmount,
  volume,
  isiOS,
  isWeixin,
  defineProperty,
  queryURL,
  imgToBase64,
  isAdapt,
  delCookie,
  isIphonex,
  debounce,
  addToBagGio,
  contactCSCGio,
  addToCollectGio,
  plpFilterGio,
  changeBrowseModeGio,
  clickMCCGio,
  clickAdjustGio,
  abtestGio,
  cardColor,
  shapeTitle,
  getStorage,
  removeStorage,
  setStorage,
  loginCallback,
  clickWelcomeSkip,
  smoothLandingAbTestGio,
  clickReplaceGio,
  finishAdjustmentGio,
  pipIsOutOfStockGio,
  adjustmentIsOutOfStockGio,
};
