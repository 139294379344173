/*
 * @FilePath: \besta-online-web\src\App.js
 * @Author: Zhanyu Shen
 * @Date: 2021-07-06 11:06:25
 * @LastEditors: Yehao Gao
 * @LastEditTime: 2022-04-22 17:24:49
 */
// import ikea from 'ikea-jssdk';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ikea from 'ikea-jssdk';
import propTypes from 'prop-types';
import Routes from './router';
import { LocalStorageValue, SessionStorageValue } from './utils/enum';
import { LOCALSTORAGE, SESSIONSTORAGE } from './utils/const';
import {
  getStorage, removeStorage, setStorage, loginCallback,
} from './utils/index';

function App({ abtest }) {
  const history = useHistory();
  const nonIkeacn = getStorage(SESSIONSTORAGE.currentPlatform) !== SessionStorageValue.IKEACN;
  const func = () => {
    if (getStorage(SESSIONSTORAGE.currentPlatform) === SessionStorageValue.SMP) {
      return;
    }
    loginCallback(history);
  };

  if (nonIkeacn) {
    ikea.bridge.on('onShow', () => {
      setStorage(LOCALSTORAGE.onShow, LocalStorageValue.TRUE, true);
      if (getStorage(SESSIONSTORAGE.currentPlatform) === SessionStorageValue.APP) {
        setStorage(SESSIONSTORAGE.loginFromBesta, SessionStorageValue.TRUE);
      }
    });
  }

  useEffect(() => {
    if (nonIkeacn) {
      if (getStorage(SESSIONSTORAGE.loginFromBesta) === SessionStorageValue.TRUE) {
        func();
        removeStorage(SESSIONSTORAGE.loginFromBesta);
      }
    }
  }, [localStorage.getItem(LOCALSTORAGE.onShow), sessionStorage.getItem(SESSIONSTORAGE.userId)]);
  // eslint-disable-next-line no-undef
  return (
    <div className="App">
      <Routes abtest={abtest} />
    </div>
  );
}

App.propTypes = {
  abtest: propTypes.string.isRequired,
};

export default App;
