/*
 * @FilePath: \besta-online-web\src\apis\mcc.js
 * @Author: yehao Gao
 * @Date: 2021-07-23 10:03:36
 * @LastEditors: Kun Yang
 * @LastEditTime: 2022-02-11 14:59:52
 */
import request from '@/utils/request';
import { getCookie } from '@/utils/index';

export function getCarList(data) { // mcc列表查询
  return request({
    // url: `/mcc/batch?shape=${data.shape}&width=${data.width}&
    // installMethod=${data.installMethod}&priceSort=${data.priceSort}&pageNum=${data.pageNum}`,
    url: '/mcc/batch',
    method: 'GET',
    params: {
      ...data,
    },
  });
}

export function addAlternative(data) { // 添加备选
  return request({
    url: '/mcc/favorites/add',
    method: 'POST',
    data: {
      ...data,
    },
  });
}

export function deleteAlternative(data) { // 取消备选
  return request({
    url: '/mcc/favorites/delete',
    method: 'DELETE',
    params: {
      ...data,
    },
  });
}

export function getAlternative(data) { // 查询备选
  return request({
    url: `/mcc/favorites/batch?userId=${data.userId}`,
    method: 'GET',
    data: {
      ...data,
    },
    headers: {
      token: getCookie('accessToken') || '',
    },
  });
}

export function getDetail(data) { // 获取MCC详细信息
  return request({
    url: '/mcc/details',
    method: 'GET',
    params: {
      ...data,
    },
  });
}

export function getBestFriends(data) { // 获取最佳搭配产品
  return request({
    url: '/mcc/bestFriends',
    method: 'GET',
    params: {
      ...data,
    },
  });
}

export function getQa() { // 查询问答组件
  return request({
    url: '/question/list',
    method: 'GET',
  });
}

export function getProducts(data) { // 查询MCC组件列表和详细参数
  return request({
    url: '/mcc/products',
    method: 'GET',
    params: {
      ...data,
    },
  });
}

export function getUserId(data) { // 获取userId
  return request({
    url: '/mcc/user/id',
    method: 'GET',
    params: {
      ...data,
    },
  });
}

export function getVpsId(data) { // mcc加入vps
  return request({
    url: '/vps/save',
    method: 'POST',
    data: {
      ...data,
    },
  });
}

export function upload(data) { // 上传图片
  return request({
    url: '/mcc/upload',
    method: 'POST',
    data: {
      ...data,
    },
  });
}

export function getQrcode(data) { // 获取二维码信息
  return request({
    url: '/mcc/getQrVal',
    method: 'POST',
    data: {
      ...data,
    },
  });
}

export function getDesignCode(data) { // 获取designCode
  return request({
    url: '/mcc/details/designCode',
    method: 'GET',
    params: {
      ...data,
    },
  });
}

export function getWidthSelect(data) { // 获取调整页宽度选择
  // let params;
  // if (data.mccComboId && data.mccComboId !== 'null') {
  //   params = {
  //     ...data,
  //   };
  // } else {
  //   params = {
  //     templateId: data.templateId,
  //   };
  // }
  console.log('params', data);
  return request({
    url: '/mcc/widthSelect',
    method: 'GET',
    params: {
      ...data,
    },
  });
}

export function getOutStockProducts(data) { // 获取缺货信息
  return request({
    url: '/mcc/outStock/productType',
    method: 'GET',
    params: {
      ...data,
    },
  });
}

export function getOutStockCity(data) { // 获取缺货城市列表
  return request({
    url: '/mcc/outStock/store/list',
    method: 'GET',
    params: {
      ...data,
    },
  });
}

export function stockOutToCSC(data) { // 缺货联系客服
  return request({
    url: '/csc/leadsInfo/submit',
    method: 'POST',
    data: {
      ...data,
    },
  });
}
export function getIsStockOut(data) { // 调整页是否缺货
  return request({
    url: '/mcc/outStock/tips',
    method: 'POST',
    data: {
      ...data,
    },
  });
}

export function getInstallPrice(data) {
  return request({
    url: '/mcc/installPrice',
    method: 'POST',
    data: {
      ...data,
    },
  });
}
