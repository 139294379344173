/*
 * @FilePath: \besta-online-web\src\redux\initState.js
 * @Author: yehao Gao
 * @Date: 2021-08-02 11:10:35
 * @LastEditors: Dai Guo
 * @LastEditTime: 2022-05-05 16:45:04
 */
import CreateRender from '@/render/initRender.ts';

const initState = {
  count: 1,
  viewModel: '1',
  alternativeNum: 0, // 备选方案数量
  schemeHeadZIndex: '9999',
  userId: null,
  priceOrderIndex: [],
  installMethodIndex: [],
  shapeTabTitle: '形状',
  widthTabTitle: '宽度',
  refresh: false, // 加入备选后特殊情况下需要强制刷新mcclist和收藏list
  openAlternative: false,
  isFromShoppingCart: false, // 是否是购物车返回的而且没有在这个页面再进行完成调整
  isGoPLP: false, // 购物车链路下在pip页点x是否回plp
  isFromQRCode: false, // 扫码进的pip页面
  pushFromAlternative: false, // 是否从收藏夹跳转到pip
};
const detailState = {
  detailInfo: {},
  combinationVisible: false, // 组合购买显示与否
  bestList: [],
  templateId: undefined,
  templateType: undefined,
  designCode: undefined,
  outStockProducts: {},
  stockoutCardVisible: false, // 缺货提醒弹窗
};

const sideBarState = {
  wallVisible: false,
  groundVisible: false,
  tvVisible: false,
};

const adjustmentState = {
  renderData: CreateRender.Initialization('1.0'),
  tabIndex: 0,
  isShowPopover: true, // 是否显示气泡弹窗
  isShowDetailPopover: true, // 是否显示查看产品细节图片弹窗
  mccComboId: undefined, // mcc组合id
  // 切换新宽度MCC后上一个MCC调整的配件信息，目前只套用框体和木质/玻璃门板
  previousAccessories: { frame: null, woodDoor: null, glassDoor: null },
  isGoReplace: false, // 是否是去替换按钮的跳转页面
};

export default {
  ...initState, ...detailState, ...sideBarState, ...adjustmentState,
};
